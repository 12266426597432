/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 31-08-2020.
 */
import React, { useEffect, useRef } from 'react'
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom'
import { AnimatedRoute } from './components/AnimatedRoute'
import FrontPage from './pages/front/FrontPage'
import Discover from './pages/discover/Discover'
import Board from './pages/board/Board'
import Search from './pages/search/Search'

import { userStore } from './store/User'
import SelectedBoard from './pages/board/SelectedBoard'
import Spinner from './components/spinner/Spinner'
import { useAuthDispatch } from './hooks/AuthContext'
import { BOARD_PAGE, DISCOVER_PAGE, SEARCH_PAGE, WELCOME_PAGE } from './constants'

import Navigation from './components/navigation/Navigation'

const Main = (props) => {
  //const [savedCuts, dispatch] = useReducer(savedCutReducer, [])
  const errorTimeout = useRef(null)

  const user = userStore((state) => state.user)
  const fetchError = userStore((state) => state.fetchError)

  const authDispatch = useAuthDispatch()

  useEffect(() => {
    userStore.getState().fetchUser()
  }, [user])

  if (fetchError) {
    console.log(' Main > fetchError = ', fetchError)
    alert(JSON.stringify(fetchError, null, 4))
    clearTimeout(errorTimeout.current)
    errorTimeout.current = setTimeout(() => {
      authDispatch({ type: 'LOGOUT' })
    }, 2000)

    const error = fetchError.err
    const message = fetchError.message
    return (
      <div>
        <p>{error}</p>
        <p>{message}</p>
        <p>
          <small>
            <b>you will be logged out in 3-4 seconds</b>
          </small>
        </p>
      </div>
    )
  }

  if (!user) {
    return (
      <div className="d-flex h-100 justify-content-center align-items-center">
        <Spinner></Spinner>
      </div>
    )
  }

  return (
    <Router>
      {/*<SideBar pageArr={PAGES}></SideBar>*/}
      <Navigation />
      <div className="position-relative h-100 " style={{}}>
        <AnimatedRoute>
          <Route exact path={WELCOME_PAGE.to}>
            <FrontPage></FrontPage>
          </Route>

          <Route exact path={BOARD_PAGE.to + `/:id`}>
            <SelectedBoard />
          </Route>

          <Route exact path={DISCOVER_PAGE.to}>
            <div className="container-fluid h-100 px-0 no-blurry">
              <Discover />
            </div>
          </Route>

          <Route exact path={BOARD_PAGE.to}>
            <Board></Board>
          </Route>

          <Route exact path={SEARCH_PAGE.to}>
            <Search></Search>
          </Route>

          <Redirect to={WELCOME_PAGE.to} />
        </AnimatedRoute>
      </div>
    </Router>
  )
}
export default Main
