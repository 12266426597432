/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 23-07-2020.
 */
import React from 'react'
import Projects from '../front/Projects'
import CreateBoard from './CreateBoard'

const Board = () => {
  return (
    <div className="container-fluid no-blurry">
      <div className="row" style={{}}>
        <CreateBoard></CreateBoard>
        <Projects></Projects>
      </div>
    </div>
  )
}
export default Board
