/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 14-07-2020.
 */
//ENDPOINTS
export const API_SEARCH = 'https://api.createwithpeggy.com/search'

export const COLOR_PLACEHOLDER = '#eaeaea'
export const COLOR_PRIMARY = '#007bff'

export const DISCOVER_PAGE = { title: 'Discover', to: `/discover`, visible: true }
export const WELCOME_PAGE = { title: 'Welcome', to: `/`, visible: true }
export const BOARD_PAGE = { title: 'Boards', to: `/board`, visible: true }
export const SEARCH_PAGE = { title: 'Inspiration', to: `/search`, visible: false }

export const PAGES = [WELCOME_PAGE, DISCOVER_PAGE, SEARCH_PAGE, BOARD_PAGE]
