/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 26-08-2020.
 */
import React, { useEffect, useRef, useState } from 'react'

const InputBox = ({
  initialTxt = '',
  inputChangeFunc,
  enterKeyDown,
  className,
  style = {},
  placeholder = 'Tigers, car, waterfall',
  type = 'text',
  handleBlurFunc,
}) => {
  const [inputTxt, setInputText] = useState(initialTxt)
  const inputChanged = useRef(false)
  useEffect(() => {
    setInputText(initialTxt)
  }, [initialTxt])

  const onChange = (e) => {
    setInputText(e.target.value)
    inputChanged.current = true
    if (inputChangeFunc) {
      inputChangeFunc(e.target.value)
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (enterKeyDown) {
        enterKeyDown(inputTxt)
      }
    }
  }

  const handleBlur = () => {
    if (inputChanged.current) {
      inputChanged.current = false
      if (handleBlurFunc) {
        handleBlurFunc()
      }
    }
  }

  return (
    <input
      type={type}
      className={className}
      placeholder={placeholder}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      style={{
        color: '#2b2b2b',
        border: 'none',
        outline: 'none',
        background: 'transparent',
        ...style,
      }}
      onChange={onChange}
      value={inputTxt}
    />
  )
}
export default InputBox
