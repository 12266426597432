/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 18-07-2020.
 */

import React, { useState } from 'react'
import { a, animated, useSpring, useTransition } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import { COLOR_PLACEHOLDER, SEARCH_PAGE } from '../constants'
import { LazyImage } from './display/LazyImage'
import { ReactComponent as DownArrow } from '../assets/svg/downArrowWhite.svg'
import BoardList from '../pages/discover/BoardList'
import { Link } from 'react-router-dom'
import { InView } from 'react-intersection-observer'
import VideoPlayer from './videoplayer/VideoPlayer'
import { userStore } from '../store/User'
import PlayButtons from './videoplayer/PlayButtons'

const imgW = 198
const imgH = 166
const bluePad = 10

export const VideoFrame = React.memo(({ imgUrl, frame, videoDuration }) => {
  const fullTime = (Math.round(Number(videoDuration)) / 100) * Number(frame.percentViewed)
  const time = Math.round((fullTime + Number.EPSILON) * 10) / 10

  const [overlayOpacity, set, stop] = useSpring(() => ({
    opacity: 0,
    config: { friction: 25, tension: 400 },
    onRest: (what) => {
      if (what.value === 0) {
        setShow(false)
      }
    },
  }))

  const globalVideo = userStore((state) => state.globalVideo)
  const togglePlaying = globalVideo === frame.cutVideo

  const validateGlobalPlaying = userStore((state) => state.validateGlobalPlaying)

  const [show, setShow] = useState(false)

  const playVideo = () => {
    validateGlobalPlaying(frame.cutVideo)
  }

  const bind = useGesture({
    onHover: ({ active }) => {
      stop()
      if (active) {
        setShow(active)
      }
      set({
        opacity: active ? 1 : 0,
      })

      if (!active && menuVisible) {
        setMenuVisible(!menuVisible)
      }
    },
  })

  const [menuVisible, setMenuVisible] = useState(false)
  const toggleSumMenu = (value) => {
    if (value) {
      setMenuVisible(value)
    } else {
      setMenuVisible(!menuVisible)
    }
  }

  const transition = useTransition(togglePlaying, {
    from: { opacity: 0, transform: 'scale(1,1)' },

    enter: {
      opacity: 1,
      transform: 'scale(1,1)',
    },
    leave: {
      opacity: 0,
      transform: 'scale(1,1)',
      config: { friction: 30, tension: 400 },
    },
  })

  return (
    <InView as="div" triggerOnce={true} {...bind()} className="mr-6 position-relative user-select-none">
      {menuVisible && <BoardList show={menuVisible} showMenuClick={toggleSumMenu} frame={frame}></BoardList>}

      {transition((style, item, t, i) => {
        return item ? (
          <a.div
            className="position-absolute d-flex h-100 justify-content-center align-items-center"
            style={{ zIndex: 100, ...style, background: 'black', marginTop: 0 }}
          >
            <VideoPlayer url={globalVideo} togglePlaying={togglePlaying}></VideoPlayer>
          </a.div>
        ) : null
      })}

      {show && (
        <animated.div
          className="position-absolute "
          style={{
            cursor: 'ew-resize',
            background: 'rgba(0,0,0,.4)',
            zIndex: 100,
            top: 0,
            right: 0,
            borderRadius: 10,
            height: imgH,
            width: imgW,
            ...overlayOpacity,
          }}
        >
          <div className="d-flex flex-column w-100 h-100 justify-content-between p-2">
            <div
              onClick={toggleSumMenu}
              className="align-self-end pointer bg-primary d-flex pr-2"
              style={{ borderRadius: 50 }}
            >
              <p className="mb-0 p-play text-white pr-1 pl-2 ">Pin</p>
              <DownArrow className="" style={{ paddingTop: 2, width: '.8rem' }}></DownArrow>
            </div>
            {/*<PlayIcon
              onClick={playVideo}
              className="align-self-center rounded-circle mt-5 bg-primary pl-1 pointer "
              style={{ fontSize: '2rem' }}
            ></PlayIcon>*/}
            <div className="align-self-start">
              <PlayButtons cutVideo={frame.cutVideo} globalVideo={globalVideo}></PlayButtons>
            </div>
          </div>

          <Link
            to={{
              pathname: SEARCH_PAGE.to,
              state: { frame: frame },
            }}
          >
            <div className="pointer d-flex py-2 justify-content-center ">
              <p className="mb-0 p-small text-primary">More like this</p>
            </div>
          </Link>
        </animated.div>
      )}

      <LazyImage
        className="flex-shrink-0 position-relative overflow-hidden"
        style={{
          pointerEvents: 'none',
          zIndex: 10,
          width: imgW,
          height: imgH,
          borderRadius: 10,
          background: COLOR_PLACEHOLDER,
        }}
        imgStyle={{ width: imgW, height: imgH, borderRadius: 10 }}
        imgUrl={imgUrl + '&w=200'}
      ></LazyImage>
      <p className="pt-1 pl-1 text-muted p-play">{time} sek</p>
    </InView>
  )
})
