/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 22-05-2019.
 */

import React, { useCallback, useRef, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import { useDrag, useGesture } from 'react-use-gesture'
import ArrowBtn from '../../pages/inspiration/ArrowBtn'

const HozScroller = ({ children }) => {
  const [boundaries, setBoundaries] = useState(0)
  //const [xpos, setXpos] = useState(0)
  const container = useRef(null)

  const [{ x }, set] = useSpring(() => ({ x: 0 }))
  //const leftBounds = useRef(0)
  // Set the drag hook and define component movement based on gesture data

  const bind = useGesture(
    {
      onDrag: ({ down, movement: [mx], offset: [x, y], cancel }) => {
        set({ x: Math.round(mx) })
      },

      onDragStart: () => {
        //measuredRef()
        const size = measuredRef.current.getBoundingClientRect()
        setBoundaries(-Math.round(size.width - 500))
      },
    },
    {
      drag: {
        bounds: { top: 0, bottom: 0, left: boundaries, right: 50 },
        rubberband: true,
        initial: () => [x.get(), 0],
      },
    }
  )

  const measuredRef = useRef()

  const move = () => {
    const curr = x.animation.to - 250
    set({ x: Math.round(curr) })
  }

  const moveLeft = () => {
    const curr = x.animation.to + 250
    const maxcurr = curr > 0 ? 0 : curr
    set({ x: Math.round(maxcurr) })
  }

  return (
    <div ref={measuredRef} className="">
      <div
        className="position-absolute d-flex justify-content-end align-items-center"
        style={{
          height: 250,
          top: 0,
          right: 0,
          background: 'linear-gradient(90deg, rgba(249,249,249,0) 0%, rgba(249,249,249,1) 70%)',
          width: 100,
          zIndex: 200,
        }}
      >
        <ArrowBtn className="pr-4" onClick={move} rotate={true} />
      </div>

      <div
        className="position-absolute d-flex justify-content-start align-items-center"
        style={{
          height: 250,
          top: 0,
          left: 0,
          background: 'linear-gradient(-90deg, rgba(249,249,249,0) 0%, rgba(249,249,249,1) 70%)',
          width: 50,
          zIndex: 20,
        }}
      >
        <ArrowBtn className="pl-3" onClick={moveLeft} rotate={false} />
      </div>
      <animated.div ref={container} className="d-flex flex-row cursor-x" {...bind()} style={{ userSelect: 'none', x }}>
        {children}
      </animated.div>
    </div>
  )
}

export default HozScroller
