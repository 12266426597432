/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 05-09-2020.
 */
import React, { useRef, useState } from 'react'
import { userStore } from '../../store/User'
import InputBox from '../../components/display/InputBox'
import { makeSlug } from '../../utils/slug'

import TextArea from '../../components/display/TextArea'
import Spinner from '../../components/spinner/Spinner'

const CreateBoard = () => {
  const boardName = useRef()
  const boardDescription = useRef()

  const [saving, setSaving] = useState(false)

  const addBoard = userStore((state) => {
    return state.addBoard
  })
  const user = userStore((state) => state.user)

  const createBoard = () => {
    if (!boardName.current || saving) {
      return
    }

    const description = boardDescription.current ? boardDescription.current.trim() : ''
    const obj = {
      project_id: makeSlug(boardName.current),
      config: {
        user_nickname: user.user_nickname.trim(),
        name: boardName.current,
        description: description,
      },
    }

    setSaving(true)
    addBoard(obj).then((res) => {
      console.log(' CreateBoard > done = ', res)
      boardName.current = ''
      boardDescription.current = ''
      setSaving(false)
    })
  }

  const onNameChange = (e) => {
    boardName.current = e
  }

  const onDescriptionChange = (e) => {
    boardDescription.current = e
  }

  return (
    <div className="col-4 ">
      <div className="row" style={{}}>
        <div className="col-10 mt-5 mb-4 mx-auto" style={{}}>
          <h2>Create Board</h2>
          <p className="p-small">
            Create a board to start pinning cuts to your project. To edit or delete boards, please head back to this
            overview.
          </p>
        </div>
      </div>
      <div className="row ">
        <div className="col-10 mx-auto">
          <div className="box" style={{}}>
            <InputBox
              inputChangeFunc={onNameChange}
              className="w-100 px-3 py-3"
              placeholder="Name your board "
              initialTxt={boardName.current}
              style={{ fontSize: '.8rem' }}
            ></InputBox>
          </div>
          <div className="box mt-3" style={{}}>
            <TextArea
              inputChangeFunc={onDescriptionChange}
              placeholder="Write your description"
              initialTxt={boardDescription.current}
              style={{ padding: '13px 13px 13px 13px', fontSize: '.8rem' }}
            ></TextArea>
          </div>
        </div>

        <div className="col-10 mx-auto mt-4 d-flex">
          <div className="w-100 d-flex justify-content-center">
            {!saving ? (
              <button
                onClick={createBoard}
                className="btn btn-primary text-primary px-4"
                style={{ background: 'white', borderRadius: 20, border: '1px solid' }}
              >
                Create
              </button>
            ) : (
              <div className="ml-5 pt-1">
                <Spinner size={4}></Spinner>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default CreateBoard
