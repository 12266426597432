/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 19-09-2020.
 */
import React, { useState } from 'react'
import { userStore } from '../store/User'
import { useAuthDispatch } from '../hooks/AuthContext'
import { ReactComponent as DownArrow } from '../assets/svg/downArrow.svg'
import { animated, useTransition } from 'react-spring'
import { useGesture } from 'react-use-gesture'

const UserProfile = ({ size = 40 }) => {
  const user = userStore((state) => state.user)
  const dispatch = useAuthDispatch()

  const [show, setShow] = useState(false)
  const bind = useGesture({
    onHover: ({ active }) => {
      setShow(active)
    },
  })

  const logoutClick = () => {
    dispatch({ type: 'LOGOUT' })
  }

  const transition = useTransition(show, {
    from: { opacity: 0, transform: 'scale(0.5,0.5)' },
    enter: {
      opacity: 1,
      transform: 'scale(1,1)',
    },
    leave: {
      opacity: 0,
      transform: 'scale(0.5,0.5)',
    },
    config: { friction: 30, tension: 700 },
  })

  return (
    <div
      {...bind()}
      className="mt-2 mr-3 position-relative w-100 d-flex justify-content-end "
      style={{ width: size, height: size }}
    >
      <div className="d-flex">
        <img className="rounded-circle" style={{ width: 32, height: 32 }} src={user.user_picture} alt="" />
        <DownArrow className="ml-2" style={{ marginTop: 0, width: '.8rem' }}></DownArrow>
      </div>
      {transition((style, item, t, i) => {
        return item ? (
          <animated.div
            className="position-absolute p-3 "
            style={{
              zIndex: 100,
              top: 30,
              right: 0,
              background: 'white',
              boxShadow: '0px 12px 25px -5px rgba(0, 0, 0, 0.25)',
              transformOrigin: 'top center',
              ...style,
            }}
          >
            <div onClick={logoutClick} className="pointer">
              <p className="p-small">Log out</p>
            </div>
          </animated.div>
        ) : null
      })}
    </div>
  )
}
export default UserProfile
