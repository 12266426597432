/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 16-09-2020.
 */
import React, { useState } from 'react'
import { ReactComponent as CloseIcon } from '../../assets/svg/close_small.svg'
import { COLOR_PRIMARY } from '../../constants'

const FilterItem = ({ data, updateFilterFunc }) => {
  const [active, setActive] = useState(data.active)

  const deactivated = '#b1b1b1'
  const toggle = () => {
    const newValue = !active
    setActive(newValue)
    setTimeout(() => {
      updateFilterFunc(newValue, data.title)
    }, 300)
  }

  return (
    <div
      onClick={toggle}
      className="btn text-white  mr-1 py-1"
      style={{
        border: `1px solid ${active ? COLOR_PRIMARY : deactivated}`,

        borderRadius: 50,
        paddingLeft: 12,
        paddingRight: 10,
      }}
    >
      <p
        className="mb-0 "
        style={{ fontSize: '.6rem', letterSpacing: 0.7, color: active ? COLOR_PRIMARY : deactivated }}
      >
        {data.title}
        {active ? <CloseIcon style={{ marginLeft: 4, marginRight: 0, width: 5, fill: '#0069db' }} /> : null}
      </p>
    </div>
  )
}
export default FilterItem
