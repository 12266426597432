/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 15-09-2020.
 */
import React, { useCallback, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import { useDropzone } from 'react-dropzone'

import { ReactComponent as UploadIcon } from '../../assets/svg/upload.svg'
import { ApiPostForm } from '../../service/API'
import Spinner from '../spinner/Spinner'
import { userStore } from '../../store/User'

const UploadBtn = ({ label = 'UPLOAD IMAGE', searchCallback }) => {
  const [cuedImages, setImages] = useState([])
  const aniProps = useSpring({ opacity: cuedImages.length !== 0 ? 1 : 0, delay: 200 })

  const queryByImage = userStore((state) => state.queryByImage)

  const [isLoading, setIsLoading] = useState(false)

  let uuIdBase = new Date().getTime()

  const onDrop = useCallback(
    (acceptedFiles) => {
      const arr = acceptedFiles.map((item) => {
        uuIdBase = uuIdBase + 100
        item.uuid = uuIdBase
        return item
      })

      //setImages(cuedImages.concat(arr))
      uploadImages(cuedImages.concat(arr))
    },
    [cuedImages]
  )

  const uploadImages = (arr) => {
    setIsLoading(true)

    const data = new FormData()
    arr.forEach((item, index) => {
      // asset is the name multer expect ---
      data.append('asset', item)
    })

    queryByImage(data).then((res) => {
      setIsLoading(false)
      if (searchCallback) {
        searchCallback(res)
      }
    })
  }

  const deleteImage = (selectedIndex) => {
    const filtered = cuedImages.filter((item, index) => {
      return index !== selectedIndex
    })
    setImages(filtered)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()} className="d-flex pointer align-items-center  " style={{ outline: 'none' }}>
      {!isLoading ? (
        <>
          <input {...getInputProps()} />
          <p className="text-primary mb-0  p-small pr-2">{label}</p>
          <UploadIcon style={{ height: '1.5rem', width: '1.5rem' }}></UploadIcon>
        </>
      ) : (
        <div className="d-flex justify-content-center" style={{ width: '6rem' }}>
          <Spinner size={5}></Spinner>
        </div>
      )}
    </div>
  )
}
export default UploadBtn
//mx-4 pr-4
