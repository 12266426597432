/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 09-09-2020.
 */
import React from 'react'
import { LazyImage } from '../../components/display/LazyImage'
import { COLOR_PLACEHOLDER } from '../../constants'
import { a, useSpring } from 'react-spring'
import { useHover } from 'react-use-gesture'

const FrontImage = ({ index, data, imageClick }) => {
  const item = data.length > 0 ? data[index] : null

  const [aniProps, set, stop] = useSpring(() => ({
    opacity: 0.5,
    transform: `scale(1)`,
    config: { friction: 25, tension: 400 },
  }))

  const bind = useHover(({ active }) => {
    stop()
    set({
      opacity: active ? 1 : 0.5,
      transform: `scale(${active ? 1.5 : 1})`,
    })
  })

  const click = () => {
    imageClick(item)
  }

  return (
    <div {...bind()} onClick={click} className="col-3 w-100 mb-4 overflow-hidden" style={{ height: 147 }}>
      <a.div
        className="position-relative pointer w-100 h-100"
        style={{ ...aniProps, background: COLOR_PLACEHOLDER, borderRadius: 4 }}
      >
        {item && (
          <LazyImage
            style={{
              pointerEvents: 'none',
              height: 147,
              opacity: 1,
            }}
            className="w-100 position-absolute overflow-hidden"
            imgStyle={{ width: '100%', height: 147, borderRadius: 4 }}
            imgUrl={item.cutImage}
          ></LazyImage>
        )}
      </a.div>
    </div>
  )
}
export default FrontImage
