import React, { useRef } from 'react'
import { Switch, useLocation } from 'react-router-dom'
import { animated, useTransition } from 'react-spring'

export const AnimatedRoute = ({ children }) => {
  const location = useLocation()
  const state = location.state
  const oldIndex = useRef(0)

  //const direction = state && state.index < oldIndex.current ? 200 : -200 // 100 //index < oldIndex.current ? 200 : -200

  oldIndex.current = state?.index ? state.index : 0

  const transition = useTransition(location, {
    key: location.pathname,
    from: { opacity: 0, transform: 'translate3d(150px,0px,0)' },
    enter: {
      opacity: 1,
      delay: 200,
      transform: 'translate3d(0px,0px,0)',
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(0px,0px,0)',
    },
    config: { friction: 30, tension: 400 },
  })

  return transition((style, item, t, i) => {
    return (
      <animated.div className="position-absolute w-100 h-100" style={{ top: 0, left: 0, ...style }}>
        <Switch location={item}>{children}</Switch>
      </animated.div>
    )
  })
}
