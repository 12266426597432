/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 18-09-2020.
 */
import React, { memo } from 'react'
import { userStore } from '../../store/User'
import get from 'lodash.get'
import InfoIcon from '../../assets/svg/info.svg'
import Popup from 'reactjs-popup'
import './info-popover.scss'

const BoardHeader = memo(({ boardId }) => {
  const boards = userStore((state) => state.boards)

  const current = boards.filter((item) => {
    return item.project_id === boardId
  })

  const title = get(current, '[0].project_name', '')
  const description = get(current, '[0].description', '')
  //description

  return (
    <>
      <div className="row" style={{}}>
        <div className="col-10">
          <div className="row" style={{}}>
            <div className="col-12 mt-4">
              <h1 className="text-capitalize">{title}</h1>
            </div>
            {description && (
              <div className="col-8 my-3">
                <p className="">{description}</p>
              </div>
            )}
          </div>
        </div>
        <div className="col-2 d-flex justify-content-end pt-4 pr-4">
          <Popup
            className="info-popup"
            trigger={
              <div className="position-relative">
                <div className="rounded-circle  " style={{ height: 30, width: 30 }}>
                  <img className="w-100 h-100" src={InfoIcon} alt="" />
                </div>
                <p
                  className="p-small  mb-0 position-absolute"
                  style={{
                    marginTop: 20,
                    marginLeft: -36,
                    transform: 'rotate(-90deg)',
                    width: 100,
                    opacity: 0.8,
                  }}
                >
                  How to use
                </p>
              </div>
            }
            position="left top"
            on={['hover', 'focus']}
            closeOnDocumentClick
          >
            <div className="p-2">
              <h3>How to use</h3>
              <p className="p-small">
                Change your mood board by drag-and-drop cuts in a desired order.
                <br />
                <br />
                Resize cuts in their bottom right corner and add sections to keep your ideas neatly organized.
              </p>
            </div>
          </Popup>
        </div>
      </div>
    </>
  )
})
export default BoardHeader

/*
       <p>
          Change your mood board by drag-and-drop cuts in a desired order. Resize cuts in their bottom right corner and
          add sections to keep your ideas neatly organized.
        </p>
*/
