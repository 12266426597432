/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 18-07-2020.
 */

import React, { memo, useEffect, useRef } from 'react'
import { animated, useSpring } from 'react-spring'

import { LazyGroup, Lazy } from 'react-lazy'

export const LazyImage = memo(({ imgUrl, imgStyle, style, className, onClick, forceEntered = false, debugName }) => {
  const unMounted = useRef(false)
  const transform = forceEntered ? 'scale(1,1)' : 'scale(0.6,0.6)'
  const [aniProps, set] = useSpring(() => ({ opacity: forceEntered ? 1 : 0, transform: transform }))
  const isInited = useRef(false)

  const handleEnter = (inView, entry) => {
    //if (inView && !isInited.current) {
    isInited.current = true
    var img = new Image()

    if (debugName) {
      console.log(' LazyImage > debugName pre= ', debugName, unMounted.current)
    }
    img.onload = () => {
      if (!unMounted.current) {
        if (debugName) {
          console.log(' LazyImage > debugName loaded = ', debugName)
        }

        set({ opacity: 1, transform: 'scale(1,1)' })
      }
    }

    img.src = imgUrl
    //}
  }

  const clickIt = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <LazyGroup onLoad={handleEnter} className={className} style={style} onClick={clickIt}>
      <animated.div
        className="back-image "
        style={{
          backgroundImage: 'url(' + imgUrl + ')',
          ...aniProps,
          ...imgStyle,
        }}
      ></animated.div>
    </LazyGroup>
  )
})
