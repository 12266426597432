/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 30-07-2020.
 */
import React, { useState } from 'react'
import { LazyImage } from '../../components/display/LazyImage'
import { COLOR_PLACEHOLDER } from '../../constants'
import { a, animated, useSpring, useTransition } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import { ReactComponent as DownArrow } from '../../assets/svg/downArrowWhite.svg'
import BoardList from '../discover/BoardList'
import PlayButtons from '../../components/videoplayer/PlayButtons'
import VideoPlayer from '../../components/videoplayer/VideoPlayer'
import { userStore } from '../../store/User'

const RabbitImage = ({ item, imgClick, isLoading = false, index, aniStyle }) => {
  const imgUrl = item.cuts.cutUrl + '&w=400'
  const frame = item.cuts
  const delay = (index / 30) * 200

  const { xy } = aniStyle

  const globalVideo = userStore((state) => state.globalVideo)
  const togglePlaying = globalVideo === item.cuts.cutVideo

  const aniScale = isLoading ? 0.8 : 1
  const aniProps = useSpring({
    opacity: isLoading ? 0.4 : 1,
    transform: `scale(${aniScale}, ${aniScale})`,
    config: { mass: 1, tension: 200, friction: 30 },
    delay: delay,
  })

  const [overlayOpacity, set, stop] = useSpring(() => ({
    opacity: 0,
    config: { friction: 25, tension: 400 },
  }))

  const bind = useGesture({
    onHover: ({ active }) => {
      stop()
      set({
        opacity: active ? 1 : 0,
      })

      if (!active && menuVisible) {
        setMenuVisible(!menuVisible)
        setMenuZindex(0)
      }
    },
  })

  const [menuVisible, setMenuVisible] = useState(false)
  const [menuZindex, setMenuZindex] = useState(1)
  const toggleSumMenu = (value) => {
    if (value) {
      setMenuZindex(100)
      setMenuVisible(value)
    } else {
      setMenuZindex(0)
      setMenuVisible(!menuVisible)
    }
  }

  const clickIt = () => {
    imgClick(item)
  }

  const transition = useTransition(togglePlaying, {
    from: { opacity: 0, transform: 'scale(1,1)' },

    enter: {
      opacity: 1,
      transform: 'scale(1,1)',
    },
    leave: {
      opacity: 0,
      transform: 'scale(1,1)',
      config: { friction: 30, tension: 400 },
    },
  })

  return (
    <animated.div
      {...bind()}
      className="position-absolute pointer p-1 "
      style={{
        zIndex: menuZindex,
        borderRadius: 8,
        transform: xy.interpolate((x, y) => `translate3d(${x}px,${y}px,0)`),
        ...aniStyle,
      }}
    >
      <BoardList show={menuVisible} showMenuClick={toggleSumMenu} frame={frame}></BoardList>
      <animated.div
        className="position-relative w-100 h-100 overflow-hidden"
        style={{
          borderRadius: 8,
          ...aniProps,
        }}
      >
        <animated.div
          className="position-absolute w-100 h-100  "
          style={{
            background: 'rgba(0,0,0,.4)',
            zIndex: 100,
            top: 0,
            right: 0,
            ...overlayOpacity,
          }}
        >
          <div className="d-flex flex-column w-100 h-100 justify-content-between  p-2">
            <div
              onClick={toggleSumMenu}
              className="pointer bg-primary d-flex pr-2 align-self-end"
              style={{ borderRadius: 50 }}
            >
              <p className="mb-0 p-small text-white pr-1 pl-2 ">Pin</p>
              <DownArrow className="" style={{ paddingTop: 2, width: '.8rem' }}></DownArrow>
            </div>

            <div onClick={clickIt} className="align-self-center justify-content-center">
              <p className="mb-0 rounded-pill bg-primary px-2 text-white p-small">More like this</p>
            </div>
            <div>
              <div className="d-inline-block d-inline">
                <PlayButtons globalVideo={globalVideo} cutVideo={item.cuts.cutVideo} />
              </div>
            </div>
          </div>
        </animated.div>

        {transition((style, item, t, i) => {
          return item ? (
            <a.div
              className="position-absolute d-flex h-100 justify-content-center align-items-center"
              style={{ zIndex: 10, ...style, background: 'black', marginTop: 0 }}
            >
              <VideoPlayer url={globalVideo} togglePlaying={togglePlaying}></VideoPlayer>
            </a.div>
          ) : null
        })}

        <LazyImage
          style={{ background: 'rgba(0,0,0,.2)' }}
          className="w-100 h-100 position-relative"
          //className="w-100 h-100 position-relative overflow-hidden pointer"
          imgStyle={{ width: '100%', height: '100%', backgroundColor: COLOR_PLACEHOLDER }}
          imgUrl={imgUrl}
        ></LazyImage>
      </animated.div>
    </animated.div>
  )
}
export default RabbitImage
