/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 31-08-2020.
 */
import React, { useState } from 'react'
import { COLOR_PLACEHOLDER } from '../constants'
import { LazyImage } from './display/LazyImage'

import VideoPlayer from './videoplayer/VideoPlayer'
import { a, animated, useSpring, useTransition } from 'react-spring'
import { userStore } from '../store/User'
import { ReactComponent as DownArrow } from '../assets/svg/downArrowWhite.svg'
import { Link } from 'react-router-dom'
import { useGesture } from 'react-use-gesture'
import BoardList from '../pages/discover/BoardList'
import PlayButtons from './videoplayer/PlayButtons'

const VideoContainer = ({ video }) => {
  const globalVideo = userStore((state) => state.globalVideo)

  //
  // in order to control how many videos are playing at once, the current video path is placed in global state.
  // this causes a problem when the same cut can be played from multiple places.
  //
  const videoIdAppen = ':' + video.cutName
  const cutVideo = video.cutVideo + videoIdAppen

  const togglePlaying = globalVideo === cutVideo || globalVideo === video.parentVideo.videoUrl
  const cleanGlobalVideo = togglePlaying ? globalVideo.split(videoIdAppen)[0] : globalVideo

  const bind = useGesture({
    onHover: ({ active }) => {
      stop()
      if (active) {
        //setShow(active)
      }
      set({
        opacity: active ? 1 : 0,
      })

      if (!active && menuVisible) {
        setMenuVisible(!menuVisible)
      }
    },
  })

  const transition = useTransition(togglePlaying, {
    from: { opacity: 0, transform: 'scale(1,1)' },

    enter: {
      opacity: 1,
      transform: 'scale(1,1)',
    },
    leave: {
      opacity: 0,
      transform: 'scale(1,1)',
      config: { friction: 30, tension: 400 },
    },
  })

  const [overlayOpacity, set, stop] = useSpring(() => ({
    opacity: 0,
    config: { friction: 25, tension: 400 },
    onRest: (what) => {
      /*if (what.value === 0) {
        setShow(false)
      }*/
    },
  }))

  const [menuVisible, setMenuVisible] = useState(false)
  const toggleSumMenu = (value) => {
    if (value) {
      setMenuVisible(value)
    } else {
      setMenuVisible(!menuVisible)
    }
  }

  return (
    <div>
      <div {...bind()} className="position-relative" style={{ width: 304, height: 208 }}>
        <div className="d-flex justify-content-between mb-1" style={{ width: 304 }}>
          <p className="p-small ">
            MOOD: <b>{video.cutMood[0].toUpperCase()}</b>
          </p>
          <p className="p-small">
            PURPOSE: <b>{video.parentVideo.videoPurpose.toUpperCase()}</b>
          </p>
        </div>

        <div className="position-relative w-75 mr-2  ml-auto ">
          {menuVisible && (
            <BoardList show={menuVisible} top={30} showMenuClick={toggleSumMenu} frame={video}></BoardList>
          )}
        </div>

        {transition((style, item, t, i) => {
          return item ? (
            <a.div
              className="position-absolute d-flex  justify-content-center align-items-center"
              style={{ ...style, width: 304, height: 208, zIndex: 100, background: 'black', marginTop: 0 }}
            >
              <VideoPlayer url={cleanGlobalVideo} togglePlaying={togglePlaying}></VideoPlayer>
            </a.div>
          ) : null
        })}

        <animated.div
          className="position-absolute"
          style={{
            zIndex: 100,
            marginTop: 22,
            top: 0,
            left: 0,
            width: 304,
            height: 208,
            ...overlayOpacity,
            background: 'rgba(0,0,0,.5)',
          }}
        >
          <div className="d-flex p-2 flex-column w-100 h-100 justify-content-between ">
            <div className="w-100 d-flex justify-content-end">
              <div
                onClick={toggleSumMenu}
                className="align-self-end pointer bg-primary d-flex pr-2"
                style={{ borderRadius: 50 }}
              >
                <p className="mb-0 p-small text-white pr-1 pl-2 ">Pin</p>
                <DownArrow className="" style={{ paddingTop: 2, width: '.8rem' }}></DownArrow>
              </div>
            </div>

            <div className="w-100 d-flex justify-content-between">
              <div className="align-items-end d-flex text-white">
                <PlayButtons
                  globalVideo={globalVideo}
                  fullVideo={video.parentVideo.videoUrl}
                  cutVideo={video.cutVideo}
                  appendToPath={videoIdAppen}
                ></PlayButtons>
              </div>
            </div>
          </div>
        </animated.div>

        <LazyImage
          className="mr-2 flex-shrink-0  pointer position-relative"
          style={{
            width: 304,
            height: 208,
            background: COLOR_PLACEHOLDER,
            zIndex: 50,
            boxShadow: '0px 10px 16px -6px rgba(88, 88, 88, 0.4)',
          }}
          imgStyle={{ overflow: 'hidden', width: 304, height: 208, borderRadius: 4 }}
          imgUrl={video.cutImage}
        ></LazyImage>

        <Link
          to={{
            pathname: '/search',
            state: { frame: video },
          }}
        >
          <animated.div className="d-flex pt-2 justify-content-center " style={{ ...overlayOpacity }}>
            <p className="pointer mb-0 p-small text-primary">More like this</p>
          </animated.div>
        </Link>
      </div>
    </div>
  )
}

export default VideoContainer
