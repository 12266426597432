export const getData = (path, query) => {
  const url: string = path + query
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json()
    })
    .then((res) => res)
    .catch((err) => {
      console.log(' API > err = ', err)
      return err
    })
}

export const DELETE = '/delete'
export const UPDATE = '/update'
export const UPDATE_PLACEMENT = '/updatePlacement'

export const ADD_CUT = '/addCut'
export const DELETE_CUT = '/deleteCut'
export const SEARCH = '/search'
export const CUTS = '/cuts'

export const post = (endpoint, data, customEndpoint) => {
  const p = customEndpoint ? customEndpoint : 'https://user.createwithpeggy.com' + endpoint

  const token = window.localStorage.getItem('token')
  return fetch(p, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json()
    })
    .then((res) => {
      // console.log(' API > res = ', res)
      return res
    })
    .catch((err) => {
      console.log(' API > err = ', err)
      return err
    })
}

export const ApiPostForm = async (endpoint, formData) => {
  const token = window.localStorage.getItem('token')
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    body: formData,
  })
    .then((res) => {
      return res
    })

    .then((res) => {
      return res.json()
    })
    .then((res) => {
      // console.log(' API > res = ', res)
      return res
    })
    .catch((err) => {
      console.log(' API > err = ', err)
      return err
    })
}

export const getUser = () => {
  const p = 'https://user.createwithpeggy.com'
  const token = window.localStorage.getItem('token')

  return fetch(p, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    redirect: 'follow',
  })
    .then((res) => {
      return res.json()
    })

    .catch((err) => {
      return Promise.reject(err)
    })
}
