/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 31-08-2020.
 */

import React, { useReducer } from 'react'

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'

const tokenKey = 'token'
export const userKey = 'peggy-user'

const AuthStateContext = React.createContext()
const AuthDispatchContext = React.createContext()

const initialStateObj = {
  count: 0,
  user: window.localStorage.getItem(userKey),
  token: window.localStorage.getItem(tokenKey),
}

const getToken = () => {
  console.log(' AuthContext > window.localStorage.getItem(tokenKey) = ', window.localStorage.getItem(tokenKey))
  return window.localStorage.getItem(tokenKey)
}
const getUser = () => {
  const str = window.localStorage.getItem(userKey)
  return str //? JSON.parse(str) : ''
}

function authReducer(state, action) {
  switch (action.type) {
    case 'increment': {
      return { count: state.count + 1 }
    }
    case 'decrement': {
      return { count: state.count - 1 }
    }
    case LOGOUT: {
      window.localStorage.setItem(tokenKey, '')
      window.localStorage.setItem(userKey, '')
      window.location.replace('/')
      return { token: '', user: '' }
    }

    case LOGIN: {
      //console.log(' AuthContext > action.payload = ', action.payload)
      const googleResponse = action.payload

      console.log(' AuthContext > googleResponse = ', googleResponse)

      //if (googleResponse.tokenObj.id_token) {
      if (googleResponse.refresh_token) {
        window.localStorage.setItem(tokenKey, googleResponse.refresh_token)
      }

      /*if (googleResponse.user_picture) {
        window.localStorage.setItem(userKey, JSON.stringify(googleResponse.user_picture))
      }*/

      return { token: getToken() }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(authReducer, initialStateObj)
  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

function useAuthState() {
  const context = React.useContext(AuthStateContext)
  if (context === undefined) {
    throw new Error('useAuthState must be used within a CountProvider')
  }
  return context
}

function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext)
  if (context === undefined) {
    throw new Error('useCountDispatch must be used within a CountProvider')
  }
  return context
}

export { AuthProvider, useAuthState, useAuthDispatch }
