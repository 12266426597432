/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 26-07-2020.
 */
import React, { useState } from 'react'
import { API_SEARCH, COLOR_PLACEHOLDER, DISCOVER_PAGE } from '../../constants'
import { usePostFetch } from '../../hooks/useFetch'

import { ReactComponent as NavigateIcon } from '../../assets/svg/navigate.svg'
import { ReactComponent as ArrowIcon } from '../../assets/svg/longArrow.svg'
import Spinner from '../../components/spinner/Spinner'
import get from 'lodash.get'
import { Link, useLocation } from 'react-router-dom'
import Button from '../../components/display/Button'
import Crumps from './Crumps'

import Masonary from './Masonary'

const rabbit = {
  type: 'rabbit',
  input: '',
  limit: 12,
}

const Search = (props) => {
  const location = useLocation()
  const first = get(location, 'state.frame', '')

  console.log(' Search > first.cutName = ', first)
  const query = { ...rabbit, input: first.cutName || '' }
  const [{ data, isLoading }, setUrl] = usePostFetch(API_SEARCH, query, { data: [] })

  const [crumps, setCrumps] = useState(first ? [first] : [])

  const crumpClick = (crump) => {
    setCrumps(crumps.concat([crump]))
    setUrl({ ...rabbit, input: crump.cutName })
  }

  const imgClick = (item) => {
    if (isLoading) {
      return false
    }
    setCrumps(crumps.concat([item.cuts]))
    const imgId = item.cuts.cutName

    setUrl({ ...rabbit, input: imgId })
  }

  return (
    <div className="container-fluid position-relative h-100">
      <div className="row" style={{}}>
        <div className="col-12 mt-4 mb-1 pl-5">
          <h3>Down the Rabbit-Hole.</h3>
          <p className="p-small" style={{ maxWidth: 600 }}>
            A place without a clear roadmap, but instead offers a visual compass for those styles too difficult to
            articulate. Click on a frame to follow down the rabbit-hole or backtrack via your breadcrumbs.
          </p>
        </div>
      </div>
      <div className="row " style={{}}>
        <div className="col-12 mt-4  d-flex align-items-center  flex-wrap">
          <div className="mr-1 pl-3 ">
            <NavigateIcon style={{ height: 25 }}></NavigateIcon>
            <ArrowIcon style={{ height: 10 }}></ArrowIcon>
          </div>

          {crumps.map((crump, index) => {
            //const showArrow = index === crumps.length - 1

            return <Crumps key={index} clickFunc={crumpClick} crump={crump}></Crumps>
          })}

          <div className="d-flex pt-2 pl-3">
            <Link to={DISCOVER_PAGE.to}>
              <Button>
                <p className="p-small" style={{ background: 'none' }}>
                  Back
                </p>
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <div className="row position-relative h-100 ">
        {isLoading === true ? (
          <div className="position-absolute h-100 w-100  " style={{ zIndex: 100 }}>
            <div className="d-flex h-75 w-100 flex-column justify-content-center align-items-center">
              <Spinner size={10}></Spinner>
            </div>
          </div>
        ) : null}

        <Masonary data={data.data} imgClick={imgClick} isLoading={isLoading}></Masonary>

        {/*      <Grid
          // Arbitrary data, should contain keys, possibly heights, etc.
          data={items}
          // Key accessor, instructs grid on how to fetch individual keys from the data set
          keys={(d) => d.key}
          // Can be a fixed value or an individual data accessor (for variable heights)
          // If you leave it undefined it will assume 100% container height
          heights={(d) => 150}
          // Optional: number of columns (make it responsive yourself using react-measure/react-media)
          columns={4}
          // Optional: space between elements
          margin={20}
          // Optional: removes the possibility to scroll away from a maximized element
          lockScroll={false}
          // Optional: delay before minimizing an opened element
          closeDelay={500}
          // Optional: animates the grid in if true (default)
          transitionMount={false}
        >
          {(video, open, toggle, videoIndex) => {
            const z = video.props.index === 3 ? 1000 : 1
            return (
              <div className="w-100 h-100 what position-absolute" style={{ zIndex: z, background: 'red' }}>
                <h1>asdasdad</h1>
                <div className="position-absolute" style={{ background: 'blue', height: 300, width: 200 }}>
                  <h2>asdasd</h2>
                  <h2>asdasd</h2>
                </div>
              </div>
            )
          }}
        </Grid>*/}
      </div>
      {/*<div className="row position-relative" style={{ height: '60vh' }}>
        {isLoading === true ? (
          <div className="position-absolute h-100 w-100 " style={{ zIndex: 100 }}>
            <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
              <Spinner size={10}></Spinner>
            </div>
          </div>
        ) : null}
        {data.data.map((item, index) => {
          return (
            <RabbitImage key={item.cuts.cutName} item={item} index={index} imgClick={imgClick} isLoading={isLoading} />
          )
        })}
      </div>*/}
    </div>
  )
}
export default Search
