/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 31-08-2020.
 */
import React from 'react'
import { LOGIN, useAuthDispatch } from '../../hooks/AuthContext'
import { GoogleLogin } from 'react-google-login'

import { ReactComponent as PeggyIcon } from '../../assets/svg/peggy.svg'

const CLIENT_ID = '735401557525-6msjs8vees39grq8pcvtl4887b48545h.apps.googleusercontent.com'

const doRefreshToken = (code) => {
  return fetch('https://user.createwithpeggy.com/token', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + code,
    },
    redirect: 'follow',
  })
    .then((res) => {
      return res.json()
    })

    .catch((err) => {
      alert(JSON.stringify(err))
    })
}

const Login = (props) => {
  const dispatch = useAuthDispatch()

  const googleLogin = (e) => {
    doRefreshToken(e.code).then((res) => {
      dispatch({ type: LOGIN, payload: { ...res.data } })
    })
  }
  const handleLoginFailure = (e) => {
    console.log(' Login > handleLoginFailure = ', e)
  }

  return (
    <div className="container-fluid h-100" style={{ background: '#eaeaea' }}>
      <div className="row h-100" style={{}}>
        <div className="col-10 col-md-8 col-lg-6 col-xl-4 h-100 d-flex mx-auto align-items-center  ">
          <div className="row p-4 box" style={{ background: '#FFFFFF', boxShadow: '0px 2px 20px 10px rgba(0,0,0,.1)' }}>
            <div className="col-12 mt-5 d-flex justify-content-center">
              <PeggyIcon style={{ width: '4rem' }}></PeggyIcon>
            </div>
            <div className="col-12 mx-auto">
              <h1 className="mb-0 mt-3 text-center">PEGGY OLSON</h1>
              <h4 className="text-primary mb-0 text-center">DATA DRIVEN CREATIVITY</h4>
            </div>
            <div className="col-10 mx-auto">
              <p className="p-small mt-3 text-center ">
                She leverages powerful AI to crawl through huge databases of video ads indexing all ideas into their
                creative elements. Doing so enables creatives with fast access to a searchable database of creativity,
                from objects, stylistic tone to moods.
              </p>
            </div>
            <div className="col-12">
              <div className="p-5 ">
                <div className="d-flex justify-content-center ">
                  <GoogleLogin
                    clientId={CLIENT_ID}
                    /*accessType="offline"*/
                    prompt="consent"
                    buttonText={
                      <p className="mb-0 pr-3">
                        <b>Login in with google</b>
                      </p>
                    }
                    onSuccess={googleLogin}
                    isSignedIn={false}
                    onFailure={handleLoginFailure}
                    cookiePolicy={'single_host_origin'}
                    responseType="code"
                  />
                </div>
              </div>
            </div>

            <div className="col-8 mx-auto">
              <p className="text-center p-small mb-0">For more information contact:</p>
              <p className="mb-0 text-center">
                <b>Martin Olesen</b>
              </p>
              <a className="" href="mailto:martin.olesen@groupm.com">
                <p className="p-small text-center">martin.olesen@groupm.com</p>
              </a>
              <p className="text-center p-small">+45 3138 6570</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Login
