/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 05-10-2020.
 */
import React, { memo, useState } from 'react'
import { ReactComponent as PeggyIcon } from '../../assets/svg/peggy.svg'
import { BOARD_PAGE, PAGES } from '../../constants'
import { Link, NavLink } from 'react-router-dom'
import { ReactComponent as DownArrow } from '../../assets/svg/downArrow.svg'
import Submenu from '../sidebar/Submenu'
import UserProfile from '../UserProfile'
import { animated, useTransition } from 'react-spring'
import { useGesture } from 'react-use-gesture'

const Navigation = memo((props) => {
  const [show, setShow] = useState(false)
  const bind = useGesture({
    onHover: ({ active }) => {
      setShow(active)
    },
  })

  const transition = useTransition(show, {
    from: { opacity: 0, transform: 'scale(0.5,0.5)' },
    enter: {
      opacity: 1,
      transform: 'scale(1,1)',
    },
    leave: {
      opacity: 0,
      transform: 'scale(0.5,0.5)',
    },
    config: { friction: 30, tension: 700 },
  })

  return (
    <div className="container-fluid px-0 bg-white">
      <div className="row box " style={{ height: 60 }}>
        <div className="col-3 ">
          <Link to={'/'} className="d-flex pl-3 py-2 pointer text-decoration-none">
            <PeggyIcon style={{ marginTop: 2, width: '2.5rem' }}></PeggyIcon>
            <h5 className="mb-0 pl-2 pt-2" style={{ color: '#000000' }}>
              Peggy Olson
            </h5>
          </Link>
        </div>

        <div className="col-6 d-flex justify-content-center" style={{ paddingTop: 20 }}>
          {PAGES.map((item, index) => {
            if (item.to === BOARD_PAGE.to) {
              return (
                <div key={index} {...bind()} className="mx-2 position-relative h-100">
                  <NavLink
                    className="text-decoration-none h-100"
                    to={item.to}
                    activeStyle={{
                      color: '#050505',
                      borderBottom: '2px solid #007bff',
                    }}
                    style={{
                      height: 40,
                      color: '#757575',
                      borderBottom: '2px solid white',
                    }}
                    className="d-flex text-decoration-none"
                  >
                    <p className="px-1" style={{ fontSize: '.9rem' }}>
                      {item.title.toUpperCase()}
                    </p>
                    <DownArrow className="ml-2" style={{ marginTop: -13, width: '.8rem' }}></DownArrow>
                  </NavLink>

                  {item.to === BOARD_PAGE.to
                    ? transition((style, item, t, i) => {
                        return item ? (
                          <animated.div
                            className="position-absolute p-3"
                            style={{
                              zIndex: 100,
                              top: 30,
                              left: -40,
                              background: 'white',
                              boxShadow: '0px 12px 25px -5px rgba(0, 0, 0, 0.25)',
                              transformOrigin: 'top center',
                              ...style,
                            }}
                          >
                            <Submenu></Submenu>
                          </animated.div>
                        ) : null
                      })
                    : null}
                </div>
              )
            }

            return (
              <NavLink
                className="h-100 mx-3 text-decoration-none"
                key={index}
                exact
                activeStyle={{
                  color: '#050505',
                  borderBottom: '2px solid #007bff',
                }}
                style={{
                  color: '#868686',
                }}
                to={item.to}
              >
                <p className=" " style={{ fontSize: '.9rem' }}>
                  {item.title.toUpperCase()}
                </p>
              </NavLink>
            )
          })}
        </div>

        <div className="col-3 pt-2 d-flex justify-content-end ">
          <UserProfile size={35} />
        </div>
      </div>
    </div>
  )
})
export default Navigation
