import React from 'react'
import './styles/styles.scss'
import Main from './Main'
import { useAuthState } from './hooks/AuthContext'
import Login from './pages/login/Login'

if (process.env.NODE_ENV !== 'production') {
  import('./styles/size-debug.scss').then((something) => {})
}

function App() {
  const { token } = useAuthState()

  return token ? <Main /> : <Login />
}

export default App
