import { useState, useEffect, useReducer } from 'react'
//import axios from 'axios'

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case 'INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case 'SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      }
    case 'ERROR':
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    default:
      throw new Error()
  }
}

const jsonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
}

export const usePostFetch = (initialUrl, query, initialData, headers = jsonHeaders) => {
  const [postData, setPostData] = useState(query)

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: query === '' ? false : true,
    isError: false,
    data: initialData,
  })

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      dispatch({ type: 'INIT' })
      //console.log(' useFetch > fetch = ')
      try {
        const result = await fetch(initialUrl, {
          method: 'POST',
          headers: headers,
          body: postData ? JSON.stringify(postData) : null,
        }) //axios(url)

        if (!didCancel) {
          const data = await result.json()
          dispatch({ type: 'SUCCESS', payload: data })
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: 'ERROR' })
        }
      }
    }

    if (postData !== '') {
      fetchData()
    } else {
      console.log(' useFetch > no postData ')
    }

    return () => {
      didCancel = true
    }
  }, [postData, headers, initialUrl])

  return [state, setPostData]
}

export const useFetch = (initialUrl, initialData) => {
  const [url, setUrl] = useState(initialUrl)

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    data: initialData,
  })

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      dispatch({ type: 'INIT' })

      try {
        const result = await fetch(url) //axios(url)

        if (!didCancel) {
          dispatch({ type: 'SUCCESS', payload: result.data })
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: 'ERROR' })
        }
      }
    }

    fetchData()

    return () => {
      didCancel = true
    }
  }, [url])

  return [state, setUrl]
}
