/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 18-09-2020.
 */
import React, { useRef, useState } from 'react'
import InputBox from '../../components/display/InputBox'
import { useSpring, a } from 'react-spring'
import { useHover } from 'react-use-gesture'
import { userStore } from '../../store/User'

const SectionDivider = ({ boardId, deleteCut, item }) => {
  const [showDelete, setDelete] = useState(false)
  const saveComment = userStore((state) => state.addCutToBoard)
  const txt = useRef('')

  const { opacity, background } = useSpring({
    background: showDelete ? '#f1f1f1' : '#f9f9f9',
    opacity: showDelete ? 1 : 0,
  })

  const bind = useHover(({ event, last, active }) => {
    setDelete(active)
  })

  const deleteClick = () => {
    deleteCut(item)
  }

  const handleBlur = () => {
    console.log(' SectionDivider > v = ', txt.current)
    console.log(' SectionDivider > item = ', item)
    const obj = {
      project_id: boardId,
      config: {
        cut_id: item.i,
        comment: txt.current,
        placement: `"w":${item.w} ,"h":${item.h}, "x":${item.x}, "y":${item.y} `,
      },
    }

    saveComment(obj).then((res) => {
      console.log(' SectionDivider > section title save = ', res)
    })
  }

  const textChange = (v) => {
    txt.current = v
  }

  return (
    <a.div
      {...bind()}
      className="pointer w-100 p-2 d-flex justify-content-between"
      style={{ cursor: 'move', borderRadius: 6, background: background }}
    >
      <InputBox
        handleBlurFunc={handleBlur}
        initialTxt={item.cut.cutComment}
        inputChangeFunc={textChange}
        placeholder="add title"
        style={{}}
      ></InputBox>
      <a.div onClick={deleteClick} style={{ opacity, paddingTop: 2 }}>
        <p
          onClick={deleteCut}
          className="pointer mb-0  text-white bg-danger rounded-circle"
          style={{
            transform: 'rotate(45deg)',
            zIndex: 100,
            top: 0,
            width: 21,
            height: 21,
            paddingLeft: 5,
            paddingTop: 0,
          }}
        >
          +
        </p>
      </a.div>
    </a.div>
  )
}
export default SectionDivider
