/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 18-09-2020.
 */
import React, { useEffect, useRef, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import Popup from 'reactjs-popup'
import { ReactComponent as CommentIconTwo } from '../../assets/svg/comment-24px_outline.svg'
import './popover.scss'
import Spinner from '../../components/spinner/Spinner'
import { userStore } from '../../store/User'

const Comment = ({ cut, boardId }) => {
  const [txt, setTxt] = useState('')
  const [saving, setSaving] = useState(false)

  const saveComment = userStore((state) => state.addCutToBoard)

  const initTxt = useRef(cut.cutComment)
  useEffect(() => {
    setTxt(cut.cutComment)
  }, [cut.cutComment])

  //console.log(' Comment > cut.comment = ', cut.cutComment)

  const showSave = txt !== initTxt.current

  const change = (e) => {
    const s = e.target.value
    setTxt(s)
  }

  const saveClick = () => {
    const obj = {
      project_id: boardId,
      config: {
        cut_id: cut.cutName,
        comment: txt,
        placement: '',
      },
    }

    setSaving(true)
    saveComment(obj).then((res) => {
      initTxt.current = txt
      setSaving(false)
    })
  }

  return (
    <div className="text-white ">
      <Popup
        on={['hover', 'focus']}
        className="comment-popup"
        trigger={<CommentIconTwo className="pointer" style={{ fill: '#2991ff' }}></CommentIconTwo>}
        position="right top"
        closeOnDocumentClick
      >
        <div className="p-1 w-100" style={{ borderRadius: 20 }}>
          <p className="p-play">Comment:</p>
          <TextareaAutosize
            value={txt}
            onChange={change}
            placeholder="Add a comment"
            className="p-small w-100 "
            style={{ border: 'none', outline: 'none', resize: 'none' }}
          />

          {showSave && (
            <div onClick={saveClick} className="w-100 d-flex justify-content-end">
              {saving && <Spinner style={{ marginRight: 15 }} size={3}></Spinner>}
              <p className="d-inline-block mb-0 pointer bg-primary text-white rounded-pill p-small px-2">Save</p>
            </div>
          )}
        </div>
      </Popup>
    </div>
  )
}
export default Comment
