/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 02-09-2020.
 */
import React, { memo, useMemo, useState } from 'react'
import VideoContainer from '../../components/VideoContainer'
import FrameContainer from './FrameContainer'
import HozScroller from '../../components/display/HozScoller'
import Spinner from '../../components/spinner/Spinner'
import { Grid } from 'mauerwerk'
import { InView } from 'react-intersection-observer'

const searchQuery = {
  type: 'keywords',
  input: 'apples, trees, green, car',
  limit: 10,
}

const random = {
  type: 'random',
  //input: 'apples, trees, green, car',
  limit: 10,
}

const ColItem = ({ video, videoIndex }) => {
  const [entered, setEntered] = useState(false)
  const handleEnter = (inView, entry) => {
    setEntered(inView)
    if (inView) {
      //setEntered(inView)
    }
  }
  return (
    <InView as="div" onChange={handleEnter} triggerOnce={false} className="d-flex flex-nowrap noselect ml-5" style={{}}>
      {entered && (
        <div key={video.ID} className="col-12 mt-4 d-flex">
          <VideoContainer video={video} />

          <div
            className="position-relative d-flex flex-row flex-nowrap mw-100 overflow-hidden"
            style={{ paddingBottom: 100 }}
          >
            <HozScroller>
              <FrameContainer video={video} videoIndex={videoIndex}></FrameContainer>
            </HozScroller>
          </div>
        </div>
      )}
    </InView>
  )
}

const DiscoverList = memo(({ data, isLoading }) => {
  const cuts = data

  const items = useMemo(() => {
    return data.map((video, videoIndex) => {
      return <ColItem key={video.ID} video={video} videoIndex={videoIndex}></ColItem>
    })
  }, [data])

  return (
    <>
      {isLoading && (
        <div className="row h-75">
          <div className="col-12 h-100 d-flex justify-content-center align-items-center">
            <Spinner size={10}></Spinner>
          </div>
        </div>
      )}

      {cuts.length === 0 && !isLoading ? (
        <div className="row mt-3">
          <div className="col-6 mx-auto mt-4">
            <p className="text-center">¯\_(ツ)_/¯</p>
            <h1 className="text-center">Oh no, you have reached a dead end!</h1>
            <p className="text-center">
              Peggy cannot find any relevant videos that match your request Either try searching for something slightly
              different, upload a visual reference, or check search term for typos.
            </p>
          </div>
        </div>
      ) : (
        <div className="row mt-3 noselect">
          <Grid
            // Arbitrary data, should contain keys, possibly heights, etc.
            data={items}
            // Key accessor, instructs grid on how to fetch individual keys from the data set
            keys={(d) => d.key}
            // Can be a fixed value or an individual data accessor (for variable heights)
            // If you leave it undefined it will assume 100% container height
            heights={(d) => 350}
            // Optional: number of columns (make it responsive yourself using react-measure/react-media)
            columns={1}
            // Optional: space between elements
            margin={0}
            // Optional: removes the possibility to scroll away from a maximized element
            lockScroll={false}
            // Optional: delay before minimizing an opened element
            closeDelay={500}
            // Optional: animates the grid in if true (default)
            transitionMount={false}
          >
            {(video, open, toggle, videoIndex) => video}
          </Grid>
        </div>
      )}
    </>
  )
})

export default DiscoverList
