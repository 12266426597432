import React from 'react'
import { ReactComponent as NextArrow } from '../../assets/svg/arrow.svg'
import { animated, useSpring } from 'react-spring'
import { useHover } from 'react-use-gesture'

const ArrowBtn = ({ rotate, onClick, className = '' }) => {
  const rotated = rotate ? 'rotate(180deg)' : 'rotate(0deg)'
  const [props, set] = useSpring(() => ({
    opacity: 1,
    transform: `scale(1)`,
    config: { friction: 15, tension: 600 },
  }))
  const bind = useHover(({ active }) => {
    set({
      //opacity: active ? 0.7 : 1,
      transform: `scale(${active ? 1.4 : 1})`,
    })
  })

  const click = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <animated.div {...bind()} className={className} style={props}>
      <NextArrow onClick={click} className="pointer" style={{ transform: rotated, height: '1.8rem' }}></NextArrow>
    </animated.div>
  )
}
export default ArrowBtn
