/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 06-10-2020.
 */

import React, { useRef, useState, useEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

import { a, useTransition } from 'react-spring'
import RabbitImage from './RabbitImage'
import { useWindowSize } from '../../hooks/useWindowResize'

const Masonary = ({ data, imgClick, isLoading }) => {
  const columns = 4 // useMedia(['(min-width: 1500px)', '(min-width: 1000px)', '(min-width: 600px)'], [5, 4, 3], 2)
  // Hook2: Measure the width of the container element
  //const [bind, { width }] = useMeasure()
  const size = useWindowSize()
  const width = size.width - 60
  const itemHeight = Math.round((size.height - 50) / 2)

  console.log(' Masonary > size = ', size)

  let heights = new Array(columns).fill(0) // Each column gets a height starting with zero
  let gridItems = data.map((child, i) => {
    const childHeight = itemHeight
    const column = heights.indexOf(Math.min(...heights)) // Basic masonry-grid placing, puts tile into the smallest column using Math.min
    const xy = [(width / columns) * column, (heights[column] += childHeight / 2) - childHeight / 2] // X = container width / number of columns * column index, Y = it's just the height of the current column

    return { ...child, xy, width: width / columns, height: childHeight / 2 }
  })
  // Hook5: Turn the static grid values into animated transitions, any addition, removal or change will be animated
  const transition = useTransition(gridItems, {
    key: (item) => item.cuts.cutName,
    from: ({ xy, width, height }) => ({ xy, width, height, opacity: 0 }),
    enter: ({ xy, width, height }) => ({ xy, width, height, opacity: 1 }),
    update: ({ xy, width, height }) => ({ xy, width, height }),
    leave: { height: 0, opacity: 0 },
    config: { mass: 2, tension: 500, friction: 100 },
    trail: 25,
  })

  return (
    <div className="position-relative w-100 h-100 mt-4 mx-4" style={{ height: Math.max(...heights) }}>
      {transition((style, item, t, i) => {
        return (
          <RabbitImage
            key={item.cuts.cutName}
            item={item}
            index={i}
            imgClick={imgClick}
            aniStyle={style}
            isLoading={isLoading}
          />
        )
      })}
    </div>
  )
}

/*{transition.map(({ item, props: { xy, ...rest }, key }) => (
        <a.div key={key} style={{ transform: xy.interpolate((x, y) => `translate3d(${x}px,${y}px,0)`), ...rest }}>
		 <div style={{ backgroundImage: item.css }} />
		 </a.div>
		 ))}*/

export default Masonary
