/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 31-08-2020.
 */
import React from 'react'
import ReactPlayer from 'react-player'

const VideoPlayer = ({ url, togglePlaying }) => {
  //const [togglePlaying, setPlaying] = useState(false)

  return <ReactPlayer width="100%" height="100%" url={url} playing={true} loop={true} />
}
export default VideoPlayer
