/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 31-08-2020.
 */
import React, { memo, useState } from 'react'
import { VideoFrame } from '../../components/VideoFrame'
import { InView } from 'react-intersection-observer'
import Button from '../../components/display/Button'

const FrameContainer = memo(({ video, videoIndex }) => {
  const [entered, setEntered] = useState(false)
  const [visibleAmount, setVisibleAmount] = useState(0)

  const handleEnter = (inView, entry) => {
    if (inView) {
      setEntered(true)
    }
  }

  const addAmount = (change) => {
    if (!change) {
      return
    }

    const total = video.parentVideo.videoCuts.length
    const max = Math.min(total, visibleAmount + 5)
    setVisibleAmount(max)
  }

  const cuts = video.parentVideo.videoCuts.slice(0, visibleAmount)

  return (
    <InView
      as="div"
      triggerOnce={true}
      className="d-flex flex-nowrap noselect ml-5  "
      onChange={handleEnter}
      style={{ marginTop: 45 }}
    >
      {entered &&
        cuts.map((frame, frameIndex) => {
          return (
            <VideoFrame
              key={frame.cutName}
              imgUrl={frame.cutUrl}
              frame={frame}
              videoDuration={video.parentVideo.videoDuration}
            ></VideoFrame>
          )
        })}
      {visibleAmount !== video.parentVideo.videoCuts.length && (
        <InView
          as="div"
          onClick={addAmount}
          className="d-flex flex-nowrap noselect ml-4  "
          onChange={addAmount}
          style={{ marginTop: 0 }}
        >
          <Button style={{}}>
            <p className=" d-inline-block mb-0 p-small">Show more</p>
          </Button>
        </InView>
      )}
    </InView>
  )
})

export default FrameContainer
