import create from 'zustand'
import { ADD_CUT, ApiPostForm, CUTS, DELETE, DELETE_CUT, getUser, post, UPDATE, UPDATE_PLACEMENT } from '../service/API'
import { API_SEARCH } from '../constants'

export const userStore = create((set, get) => ({
  fetchError: null,
  user: null,
  profile: null,
  boards: [],
  pinItem: {
    projectName: 'my very extremy long long board name',
    imgPath: '',
  }, //'https://image.createwithpeggy.com/?path=cuts/ID3/cut-2.png'
  currentQuery: {
    savedQueryTxt: '',
    isLoading: false,
    data: { similarCuts: [] },
  },

  globalVideo: '',
  validateGlobalPlaying: (path) => {
    set({ globalVideo: path })
  },

  getCuts: (boardId) => {
    return post(CUTS, { project_id: boardId }).then((res) => {
      console.log(' App > res = ', res.data)
      return res.data
    })
  },

  updateCutPlacement: (data) => {
    return post(UPDATE_PLACEMENT, data).then((res) => {
      console.log(' placement saved = ', res)
      return res
    })
  },

  /**
   *
   * @param data {
    project_id: projectId,
    config: {
      cut_id: cutId,
      comment: '',
      placement: '',
    },
   * @return {Promise<*>}
   */

  addCutToBoard: (data, imgPath, projectName) => {
    console.log(' User > add = ')
    set({ pinItem: { projectName: projectName, imgPath: imgPath } })
    return post(ADD_CUT, data).then((res) => {
      const boards = get().boards.filter((board) => {
        return board.project_id === data.project_id
      })

      // we need to map again to limit updates otherwise we would update all boards everytime
      if (boards.length === 1 && !boards[0].image) {
        console.log(' User > Add image = ')
        const updatedBoards = get().boards.map((board) => {
          if (board.project_id === data.project_id) {
            board.image = imgPath
          }
          return board
        })
        set({ boards: updatedBoards })
      }

      return res
    })
  },

  /**
   * endpoint https://user.createwithpeggy.com/deleteCut
   * @param data
   * {
        project_id: "project_ford"
        config: {
            "cut_id": "ID9/cut-3.png"
        }
      }
   */

  deleteCut: (data) => {
    return post(DELETE_CUT, data).then((res) => {
      // it should be removed in the board
    })
  },

  addBoard: (data, isEditing = false) => {
    return post(UPDATE, data).then((res) => {
      console.log(' User > res = ', res)
      const curentBoards = get().boards

      if (!res.result) {
        return res
      }

      if (isEditing) {
        const updatedBoard = res.data
        const updatedArr = curentBoards.map((board) => {
          if (board.project_id === data.project_id) {
            console.log(' User > found = ', updatedBoard)
            return updatedBoard
          }
          return board
        })

        set({ boards: updatedArr })
        return updatedArr
      }

      const newArr = curentBoards.concat([res.data])
      set({ boards: newArr })
      return newArr
    })
  },

  deleteBoard: (data) => {
    post(DELETE, data).then((res) => {
      console.log(' App > delete = ', res)
      set((state) => {
        const arr = state.boards.filter((board) => {
          return board.project_id !== data.project_id
        })
        return { boards: arr }
      })
    })
  },

  // this is called to after getting a code from google.
  // the result contains the projects and a refresh token and user profile info
  fetchUser: () => {
    if (get().user) {
      return
    }
    return getUser()
      .then((res) => {
        if (!res.result) {
          set({ fetchError: res })
        } else {
          const projects = res.data.projects || []
          set({ boards: projects, user: res.data.profile })
        }
        return res
      })
      .catch((err) => {
        set({ fetchError: err.toString() })
        return err
      })
  },

  queryByImage: (data) => {
    const currentQuery = get().currentQuery
    set({ currentQuery: { ...currentQuery, savedQueryTxt: '', isLoading: true } })

    const p = 'https://api.createwithpeggy.com/image'
    return ApiPostForm(p, data).then(
      (res) => {
        if (res.result === false) {
          alert('Images could not be uploaded')
          return { result: false }
        }

        const currentQuery = get().currentQuery
        set({ currentQuery: { ...currentQuery, isLoading: false, ...res } })

        return res
      },
      (err) => {
        console.log(' UploadPage > err = ', err)
      }
    )
  },

  queryPeggy: (data) => {
    const currentQuery = get().currentQuery
    set({ currentQuery: { ...currentQuery, savedQueryTxt: data.input, isLoading: true } })
    return post(null, data, API_SEARCH).then((res) => {
      //console.log(' disover Seearch ', res)
      const currentQuery = get().currentQuery
      set({ currentQuery: { ...currentQuery, isLoading: false, ...res } })
    })
  },
}))
