/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 04-09-2020.
 */
import React, { useEffect, useRef, useState } from 'react'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import RGL, { WidthProvider } from 'react-grid-layout'
import { Link, useParams } from 'react-router-dom'
import { userStore } from '../../store/User'

import { DISCOVER_PAGE } from '../../constants'
import BoardCut from './BoardCut'

import SectionDivider from './SectionDivider'
import BoardHeader from './BoardHeader'
import AddSectionBtn from './AddSectionBtn'
import Spinner from '../../components/spinner/Spinner'

const ReactGridLayout = WidthProvider(RGL)

const placementObj = {
  project_id: '',
  config: { placement: [] },
}
const SelectedBoard = (props) => {
  const [cuts, setCuts] = useState([])
  const [error, setError] = useState(false)
  const deleteCut = userStore((state) => state.deleteCut)
  const params = useParams()

  const rawCuts = useRef([])
  const id = params.id

  const fetchCuts = userStore((state) => state.getCuts)
  const boards = userStore((state) => state.boards)
  const updateCutPlacement = userStore((state) => state.updateCutPlacement)

  useEffect(() => {
    fetchCuts(id).then((res) => {
      if (res.cuts.length !== 0) {
        rawCuts.current = res.cuts
        setCuts(generateLayout(res.cuts))
      } else {
        setError(true)
      }
    })
  }, [id, fetchCuts])

  const rowHeight = 30
  const gridRef = useRef()
  const cols = 4

  const generateLayout = (loadedCuts) => {
    if (!loadedCuts || error) {
      return []
    }

    return loadedCuts.map((item, i) => {
      const k = item.cutPlacement
      const placement = k.length > 3 ? JSON.parse(k) : { x: 0, y: 0, h: 4, w: 2 }

      const isSection = item.cutName.indexOf('section') === -1 ? false : true
      /*if (isSection) {
        console.log(' SelectedBoard > placement = ', placement)
      }*/
      const minWidth = isSection ? 12 : 1
      const maxWidth = 12

      return {
        cut: item,
        x: placement.x,
        y: placement.y,
        w: isSection ? 12 : placement.w,
        h: placement.h,

        i: item.cutName,
        static: false,
        minW: minWidth,
        minH: isSection ? 1 : 4,
        maxW: maxWidth,
        maxH: 8,
        isBounded: false,
        isDraggable: true,
        isResizable: isSection ? false : true,
      }
    })
  }

  // ON LAYOUT CHANGE
  //---------------------------------------------------------------------------------------

  const onLayoutChange = (layout) => {
    const obj = { ...placementObj }
    obj.project_id = id
    obj.config.placement = layout
    updateCutPlacement(obj)
  }

  const deleteSection = (deleteItem) => {
    deleteCutClick(deleteItem)
  }

  const deleteCutClick = (deleteItem) => {
    const obj = {
      project_id: id,
      config: {
        cut_id: deleteItem.i,
      },
    }

    const updatedArr = cuts.filter((item) => {
      return item.i !== deleteItem.i
    })
    setCuts(updatedArr)
  }

  const itemsChildren = React.useMemo(() => {
    return cuts.map((item, i) => {
      if (!item.isResizable) {
        return (
          <div
            key={item.i}
            data-grid={item}
            style={{
              background: '#FFFFFF',
            }}
          >
            <SectionDivider boardId={id} item={item} deleteCut={deleteSection}></SectionDivider>
          </div>
        )
      }

      return (
        <span key={item.i} data-grid={item}>
          <BoardCut boardId={id} item={item} deleteCut={deleteCutClick}></BoardCut>
        </span>
      )
    })
  }, [cuts])

  const addItem = (placement) => {
    if (cuts.length === 0) {
      return
    }

    const cutId = 'section_' + new Date().getTime()
    const newCut = {
      cut: { cutComment: '', cutPlacement: '' },
      isResizable: false,
      isDraggable: true,
      static: false,
      y: 0,
      w: 12,
      h: 1,
      x: 0,
      i: cutId,
      minW: 11,
      minH: 1,
      maxW: 12,
      maxH: 1,
    }

    const obj = {
      project_id: id,
      config: {
        cut_id: cutId,
        comment: '',
        placement: `"w":${newCut.w} ,"h":${newCut.h}, "x":${newCut.x}, "y":${newCut.y} `,
      },
    }

    // dont call this.addChild(cut manually)
    //addCutToBoard(obj, '').then((res) => {})

    if (placement === 'top') {
      // loop through all items and icreas the Y position by one
      const newMap = cuts.map((item) => {
        item.y = item.y + 1
        return item
      })
      setCuts([newCut].concat(newMap))
      return
    }

    if (placement === 'bottom') {
      let maxY = 0
      const newMap = cuts.map((item) => {
        maxY = item.y >= maxY ? item.y : maxY
        return item
      })

      newCut.y = maxY
      setCuts(cuts.concat([newCut]))
      return
    }
  }

  return (
    <div className="container-fluid">
      <BoardHeader boardId={id}></BoardHeader>
      <div className="row" style={{}}>
        {cuts.length === 0 && error && (
          <div className="col-12">
            <div className="text-black-50 mt-3">
              <h6>Peggy couldn't find any cuts pinned to this board </h6>
              <Link to={DISCOVER_PAGE.to}>Go to the Discover page to find cuts</Link>
            </div>
          </div>
        )}

        {cuts.length === 0 && !error && (
          <div className="col-12 ml-4 pt-2">
            <Spinner></Spinner>
          </div>
        )}

        <div className="col-12 mb-6  position-relative h-100 ">
          <AddSectionBtn addItem={addItem} placement="top" style={{ top: 0 }} show={cuts.length} />
          <AddSectionBtn addItem={addItem} placement="bottom" style={{ bottom: 0 }} label show={cuts.length} />

          <div className="">
            <ReactGridLayout
              innerRef={gridRef}
              className="layout"
              onLayoutChange={onLayoutChange}
              rowHeight={rowHeight}
              cols={cols}
              //verticalCompact={true}
              isBounded={false}
              margin={[30, 30]}
              //layout={cuts}
              isDroppable={false}
            >
              {itemsChildren}
            </ReactGridLayout>
          </div>
        </div>
      </div>
    </div>
  )
}

const DropCut = ({ item }) => {
  return (
    <div
      className="droppable-element "
      style={{ width: 100, height: 100, background: 'green' }}
      draggable={true}
      unselectable="on"
      // this is a hack for firefox
      // Firefox requires some kind of initialization
      // which we can do by adding this attribute
      // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
      onDragStart={(e) => e.dataTransfer.setData('text/plain', '')}
    >
      Droppable Element (Drag me!)
    </div>
  )
}

export default SelectedBoard
