/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 28-07-2020.
 */
import React from 'react'
import { userStore } from '../../store/User'
import Project from './Project'
import { BOARD_PAGE } from '../../constants'
import { makeSlug } from '../../utils/slug'

const Projects = ({ children }) => {
  const boards = userStore((state) => state.boards)
  const deleteBoard = userStore((state) => state.deleteBoard)

  const deleteClick = (index) => {
    const id = boards[index].project_id
    deleteBoard({ project_id: id })
  }

  return (
    <div className="col-8 mb-3">
      <div className="row" style={{}}>
        <div className="col-12 pl-5 mx-auto mt-5 mb-4" style={{}}>
          <h2 className="pl-2">Your boards</h2>
        </div>
      </div>

      <div className="row" style={{}}>
        <div className="col-12 pl-5 mx-auto d-flex flex-wrap" style={{}}>
          {boards.map((item, index) => {
            const to = BOARD_PAGE.to + '/' + makeSlug(item.project_name)
            return (
              <div key={index} className="mb-4 px-3 flex-wrap" style={{ minWidth: 250 }}>
                <Project item={item} index={index} itemHeight={150} deleteClick={deleteClick} to={to}></Project>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Projects
