/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 07-11-2020.
 */
import React, { useRef, useState } from 'react'
import { userStore } from '../../store/User'
import { makeSlug } from '../../utils/slug'
import InputBox from '../../components/display/InputBox'
import TextArea from '../../components/display/TextArea'
import Spinner from '../../components/spinner/Spinner'
import Popup from 'reactjs-popup'

const EditBoardModal = ({ selectedBoard }) => {
  const boardName = useRef(selectedBoard.project_name)
  const boardDescription = useRef(selectedBoard.description)
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(null)
  const [saving, setSaving] = useState(false)

  const addBoard = userStore((state) => {
    return state.addBoard
  })
  const user = userStore((state) => state.user)

  const createBoard = () => {
    if (!boardName.current || saving) {
      return
    }

    const description = boardDescription.current ? boardDescription.current.trim() : ''

    const obj = {
      project_id: selectedBoard.project_id,
      config: {
        new_project_id: makeSlug(boardName.current) === selectedBoard.project_id ? '' : makeSlug(boardName.current),
        user_nickname: user.user_nickname.trim(),
        name: boardName.current,
        image: selectedBoard.image,
        description: description,
      },
    }

    setSaving(true)
    addBoard(obj, true).then((res) => {
      setSaving(false)
      if (res.err) {
        setError(res)
        return
      }

      boardName.current = ''
      boardDescription.current = ''

      setOpen(false)
    })
  }

  const onNameChange = (e) => {
    boardName.current = e
  }

  const onDescriptionChange = (e) => {
    boardDescription.current = e
  }

  return (
    <Popup
      open={open}
      modal
      nested
      className="board-popup"
      style={{ background: 'blue' }}
      trigger={
        <div className="position-absolute pl-2 pr-3  pt-2 pb-1" style={{ zIndex: 200, top: 4, right: 0 }}>
          <p
            className="mb-0 pointer rounded-pill text-white"
            style={{ background: 'rgba(0,0,0,.6)', padding: '0px 6px 0px 6px', fontSize: '.8rem' }}
            onClick={() => setOpen((o) => !o)}
          >
            <small>EDIT</small>
          </p>
        </div>
      }
      position="bottom center"
      closeOnDocumentClick
    >
      {(close) => (
        <>
          <div className="container-fluid ">
            <div className="col-12 py-3 ">
              <div className="row" style={{}}>
                <div className="col-10 mt-3 mb-3 mx-auto" style={{}}>
                  <h2>Edit Board</h2>
                </div>
              </div>
              <div className="row ">
                <div className="col-10 mx-auto">
                  <div className="box" style={{}}>
                    <InputBox
                      inputChangeFunc={onNameChange}
                      className="w-100 px-3 py-3"
                      placeholder="Name your board "
                      initialTxt={boardName.current}
                      style={{ fontSize: '.8rem' }}
                    ></InputBox>
                  </div>
                  {error !== null ? <p className="mt-2 text-danger p-small">{error.err}</p> : null}
                  <div className="box mt-3" style={{}}>
                    <TextArea
                      inputChangeFunc={onDescriptionChange}
                      placeholder="Write your description"
                      initialTxt={boardDescription.current}
                      style={{ padding: '13px 13px 13px 13px', fontSize: '.8rem' }}
                    ></TextArea>
                  </div>
                </div>

                <div className="col-10 mx-auto mt-4 d-flex">
                  <div className="w-100 d-flex justify-content-center">
                    {!saving ? (
                      <button
                        onClick={createBoard}
                        className="btn btn-primary text-primary px-4"
                        style={{ background: 'white', borderRadius: 20, border: '1px solid' }}
                      >
                        Update
                      </button>
                    ) : (
                      <div className="ml-5 mb-3 pt-1">
                        <Spinner size={4}></Spinner>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Popup>
  )
}
export default EditBoardModal
