/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 14-07-2020.
 */
import React, { useRef } from 'react'
import InputBox from '../../components/display/InputBox'

import { ReactComponent as SearchIcon } from '../../assets/svg/search.svg'
import Button from '../../components/display/Button'
import { usePostFetch } from '../../hooks/useFetch'
import { API_SEARCH, BOARD_PAGE, COLOR_PRIMARY, DISCOVER_PAGE } from '../../constants'
import FrontImage from './FrontImage'
import { userStore } from '../../store/User'
import Project from './Project'
import { makeSlug } from '../../utils/slug'
import { Link, useHistory } from 'react-router-dom'
import UploadBtn from '../../components/upload/UploadBtn'

const random = {
  type: 'random',
  limit: 16,
}
const FrontPage = ({ projects, setKeywords }) => {
  let history = useHistory()

  const searchTxt = useRef('')

  const searchTextUpdate = (v) => {
    searchTxt.current = v
  }

  const search = () => {
    history.push(DISCOVER_PAGE.to, { searchTxt: searchTxt.current })
  }

  const searchImageClick = (item) => {
    const labels = item.labels.slice(0, 4)
    let str = ''
    labels.forEach((item, index) => {
      str += item.keyword + ', '
    })

    history.push(DISCOVER_PAGE.to, { searchTxt: str })
  }

  const searchCallback = (res) => {
    const labels = res.data.keyword.slice(0, 4)
    let str = ''
    labels.forEach((item, index) => {
      if (index === labels.length - 1) {
        str += item
      } else {
        str += item + ', '
      }
    })

    history.push(DISCOVER_PAGE.to, { searchTxt: str })
  }

  const boards = userStore((state) => state.boards)

  const dummyItems = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  const [{ data }] = usePostFetch(API_SEARCH, random, { data: { keyword: [], similarCuts: [] } })

  const filtereBoards = boards
    .concat([])
    .sort((a, b) => Number(b.create_time) - Number(a.create_time))
    .slice(0, 5)

  return (
    <div className="container-fluid no-blurry">
      <div className="row " style={{ height: '30%' }}>
        <div className="col-12 mx-auto d-flex justify-content-center align-items-end h-100">
          <div className="row" style={{}}>
            <div className="col-8 mt-6 mx-auto">
              <h1 className="text-center">
                <b>Welcome to Peggy </b>
              </h1>
              <p className="text-center">
                An AI driven platform that seeks to inspire and align creative ideas in content production. To create
                animatics you will need to create a project in the ‘board’ section. Once the board is created please
                embark on your journey by searching or uploading a visual reference.
              </p>
            </div>
            <div className="col-12 mt-5">
              <p className="text-center p-small">Search by Keywords</p>
              <div className="d-flex justify-content-center mt-3">
                <div
                  className="pl-3 py-1 w-50"
                  style={{
                    background: '#FFFFFF',
                    border: '1px solid #dfdfdf',
                    boxShadow: '0px 5px 16px -2px rgba(88, 88, 88, 0.2)',
                    borderRadius: 50,
                  }}
                >
                  <SearchIcon className="" style={{ height: '1.5rem' }}></SearchIcon>
                  <InputBox className="py-2 ml-3 " enterKeyDown={search} inputChangeFunc={searchTextUpdate}></InputBox>
                </div>
              </div>
            </div>
            <div className="col-12 mt-4 d-flex justify-content-center">
              <Link
                className="mx-2"
                to={{
                  pathname: DISCOVER_PAGE.to,
                  state: { searchTxt: searchTxt.current },
                }}
              >
                <Button onClick={search} style={{ paddingTop: 10, paddingBottom: 10 }} filled>
                  SEARCH
                </Button>
              </Link>

              <div
                className="rounded-pill d-flex align-items-center px-3"
                style={{ border: `1px solid ${COLOR_PRIMARY}` }}
              >
                <UploadBtn searchCallback={searchCallback} label="SEARCH BY IMAGE"></UploadBtn>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*PROJECTS ---------------------*/}

      {filtereBoards.length > 0 && (
        <div className="row mb-4 mt-6" style={{}}>
          <div className="col-12 mb-3">
            <p className="text-center p-small">
              Your latest boards
              <Link className="pl-2" to={BOARD_PAGE.to}>
                View all
              </Link>
            </p>
          </div>
          <div className="col-12 d-flex justify-content-center flex-wrap">
            {filtereBoards.map((project, index) => {
              const to = BOARD_PAGE.to + '/' + makeSlug(project.project_name)

              return (
                <div key={index} className="mr-4 mb-2" style={{ width: 200 }}>
                  <Project item={project} to={to}></Project>
                </div>
              )
            })}
            {/*<div className="h-100 pb-4 d-flex align-items-center " style={{ width: 200 }}>
              <p className="p-small mr-2">more</p> <LongArrow style={{ height: '2rem', width: '2rem' }}></LongArrow>
            </div>*/}
          </div>
        </div>
      )}

      <div className="row mx-auto" style={{ maxWidth: 1400 }}>
        <div className="col-12 mt-4 mb-3">
          <p className="text-center p-small">Click an image to find similar</p>
        </div>

        {dummyItems.map((item, index) => {
          return (
            <FrontImage
              key={index}
              imageClick={searchImageClick}
              data={data.data.similarCuts}
              index={index}
            ></FrontImage>
          )
        })}
      </div>
    </div>
  )
}
export default FrontPage
