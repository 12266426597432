/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 28-07-2020.
 */
import React from 'react'

const Button = ({ children, filled = false, className = '', onClick, style = {} }) => {
  if (filled) {
    return (
      <button
        onClick={onClick}
        className="btn btn-primary text-white px-4 bg-primary"
        style={{ borderRadius: 50, ...style }}
      >
        <p className="p-small mb-0">{children}</p>
      </button>
    )
  }

  return (
    <button
      onClick={onClick}
      className="btn btn-primary text-primary px-4 "
      style={{ background: 'white', borderRadius: 20, border: '1px solid', ...style }}
    >
      {children}
    </button>
  )
}
export default Button
