/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 18-09-2020.
 */
import React from 'react'
import { useSpring, a } from 'react-spring'

const AddSectionBtn = ({ addItem, placement, show, label, style = {} }) => {
  const aniProps = useSpring({
    opacity: show ? 1 : 0,
    delay: 2000,
    transform: `translate3d(0px,${show ? 0 : -50}px,0px)`,
  })

  const addClick = () => {
    addItem(placement)
  }

  return (
    <a.div className="position-absolute" style={{ zIndex: 100, ...style, ...aniProps }}>
      {label && (
        <p
          className="p-small  mb-0 "
          style={{
            marginLeft: 7,
            paddingBottom: 70,
            transform: 'rotate(-90deg)',
          }}
        >
          add section
        </p>
      )}
      <p
        onClick={addClick}
        className="position-relative pointer mb-0 text-white bg-primary rounded-circle"
        style={{ width: 21, height: 21, paddingLeft: 5, paddingTop: 0 }}
      >
        +
      </p>
    </a.div>
  )
}
export default AddSectionBtn
