/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 21-09-2020.
 */
import React from 'react'
import { LazyImage } from '../../components/display/LazyImage'
import { COLOR_PLACEHOLDER } from '../../constants'
import { ReactComponent as ArrowIcon } from '../../assets/svg/longArrow.svg'
import { useSpring, animated } from 'react-spring'

const Crumps = ({ crump, clickFunc }) => {
  const imgUrl = crump.cutUrl ? crump.cutUrl : crump.cutImage

  /*const [aniProps, set, stop] = useSpring(() => (
     opacity: 1, from: { opacity: 0 }
    config: { friction: 25, tension: 400 },
  }))*/

  const aniProps = useSpring({
    opacity: 1,
    transform: 'translate3d(0px,0px,0)',
    from: { opacity: 0, transform: 'translate3d(-150px,0px,0)' },
    config: { friction: 20, tension: 220 },
  })

  const click = () => {
    clickFunc(crump)
  }

  return (
    <div onClick={click} className="d-flex align-items-center mx-2 mb-2 pt-3">
      <LazyImage
        className="pointer rounded-circle overflow-hidden mr-3"
        imgStyle={{ width: 45, height: 45, backgroundColor: COLOR_PLACEHOLDER }}
        imgUrl={imgUrl}
        forceEntered={false}
        debugName={'crump'}
      ></LazyImage>

      <animated.div style={{ ...aniProps }}>
        <ArrowIcon style={{ height: 10, marginTop: -5 }}></ArrowIcon>
      </animated.div>
    </div>
  )
}
export default Crumps
