/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 18-07-2020.
 */
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import DiscoverList from './DiscoverList'
import get from 'lodash.get'
import InputBox from '../../components/display/InputBox'
import { ReactComponent as SearchIcon } from '../../assets/svg/search.svg'
import Button from '../../components/display/Button'
import UploadBtn from '../../components/upload/UploadBtn'

import FilterItem from './FilterItem'

import { userStore } from '../../store/User'
import PinAnimation from '../../components/pin-animation/PinAnimation'

const searchQuery = {
  type: 'keywords',
  input: 'apples, trees, green, car',
  limit: 50,
}

const random = {
  type: 'random',
  input: '',
  limit: 50,
}

const Discover = memo(({}) => {
  const location = useLocation()
  const frontPageSearchTxt = get(location, 'state.searchTxt', '')

  const queryPeggy = userStore((state) => state.queryPeggy)
  const { data, isLoading, savedQueryTxt } = userStore((state) => state.currentQuery)

  const initTxt = frontPageSearchTxt ? frontPageSearchTxt : savedQueryTxt
  const queryRef = useRef(initTxt)
  const searchTxt = queryRef.current ? queryRef.current : frontPageSearchTxt
  const [query, setQuery] = useState(searchTxt)
  const [visibleFilter, setVisibleFilter] = useState([])

  const searchTextUpdate = (v) => {
    queryRef.current = v
  }

  const search = () => {
    if (queryRef.current) {
      setQuery(queryRef.current)
    } else {
      console.warn(' Discover > EMPTY SEARCH = ')
    }
  }

  useEffect(() => {
    const noCommas = query.replace(/\s+/g, ' ')
    const nomultipl = noCommas.replace(/,+/g, '')
    const q = nomultipl.split(' ').join(',')
    const newQuery = searchTxt ? { ...searchQuery, input: q } : { ...random }

    if (data.similarCuts.length === 0 || savedQueryTxt !== newQuery.input) {
      queryPeggy(newQuery)
    }
  }, [query])

  const filters = useMemo(() => {
    return data.similarCuts.reduce(
      (acc, item) => {
        const mood = item.cutMood[0]
        const addToFilter = acc.raw.indexOf(mood) === -1 ? true : false
        if (addToFilter) {
          acc.raw.push(mood)
          acc.items.push({ title: mood, active: true })
        }
        return acc
      },
      { raw: [], items: [] }
    )
  }, [data.similarCuts])

  const updateFilters = (value, title) => {
    if (!value) {
      const newFilter = visibleFilter.concat([title])
      setVisibleFilter(newFilter)
    }
    if (value) {
      const f = visibleFilter.filter((item) => {
        return item !== title
      })

      setVisibleFilter(f)
    }
  }

  const filteredData = data.similarCuts
    .filter((item) => {
      const mood = item.cutMood[0]
      const keep = visibleFilter.indexOf(mood) === -1 ? true : false

      return keep
    })
    .filter((item) => {
      const removedBranding = visibleFilter.indexOf('Branding') > -1
      const removeBrand = removedBranding ? item.parentVideo.videoPurpose === 'Branding' : false

      return !removeBrand
    })
    .filter((item) => {
      const removedTactical = visibleFilter.indexOf('Tactical') > -1
      const removedTact = removedTactical ? item.parentVideo.videoPurpose === 'Tactical' : false
      return !removedTact
    })

  return (
    <>
      <PinAnimation></PinAnimation>
      <div className="row" style={{}}>
        <div className="col-9 mt-4 " style={{}}>
          <div className="pl-4">
            <div
              className="pl-3 w-100 py-2 d-flex"
              style={{
                background: '#FFFFFF',
                border: '1px solid #dfdfdf',
                boxShadow: '0px 5px 10px -2px rgba(88, 88, 88, 0.08)',
                borderRadius: 50,
              }}
            >
              <SearchIcon className="mt-2 " style={{ height: '1.5rem' }}></SearchIcon>
              <InputBox
                initialTxt={searchTxt}
                className="ml-3 "
                style={{ paddingTop: 8, paddingBottom: 5, width: '100%' }}
                inputChangeFunc={searchTextUpdate}
                enterKeyDown={search}
              ></InputBox>
            </div>
          </div>
        </div>

        <div className="col-3 mt-4 d-flex">
          <Button onClick={search} style={{ paddingTop: 0, paddingBottom: 0, marginRight: 15 }} filled>
            SEARCH
          </Button>
          <UploadBtn></UploadBtn>
        </div>

        <div className="col-12 d-flex mt-3 ml-4 mb-5">
          <p className="p-small mb-0 pt-1 mr-2" style={{ paddingLeft: 8 }}>
            Mood:
          </p>
          {filters.items.map((filter) => {
            return <FilterItem key={filter.title} data={filter} updateFilterFunc={updateFilters}></FilterItem>
          })}
          <p className="p-small mb-0 pt-1 ml-3 mr-2">purpose:</p>
          <FilterItem
            key="branding"
            updateFilterFunc={updateFilters}
            data={{ title: 'Branding', active: true }}
          ></FilterItem>
          <FilterItem
            key="tactical"
            updateFilterFunc={updateFilters}
            data={{ title: 'Tactical', active: true }}
          ></FilterItem>
        </div>
      </div>

      <DiscoverList data={filteredData} isLoading={isLoading}></DiscoverList>
    </>
  )
})
export default Discover
