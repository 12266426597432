/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 21-09-2020.
 */
import React from 'react'
import { userStore } from '../../store/User'

const PlayButtons = ({ globalVideo, cutVideo, fullVideo, appendToPath = '' }) => {
  const validateGlobalPlaying = userStore((state) => state.validateGlobalPlaying)

  const stopPlaying = () => {
    console.log(' PlayButtons > stop = ')
    validateGlobalPlaying('')
  }

  const playCut = () => {
    validateGlobalPlaying(cutVideo + appendToPath)
  }
  const playFullVideo = () => {
    validateGlobalPlaying(fullVideo)
  }

  return (
    <>
      {cutVideo && (
        <div className="mr-1 ">
          {globalVideo === cutVideo + appendToPath ? (
            <StopBtn onClick={stopPlaying} />
          ) : (
            <p onClick={playCut} className="pointer bg-primary text-white rounded-pill p-play px-2">
              PLAY CUT
            </p>
          )}
        </div>
      )}

      {fullVideo && (
        <div className="">
          {globalVideo === fullVideo ? (
            <StopBtn onClick={stopPlaying} />
          ) : (
            <p onClick={playFullVideo} className="pointer bg-primary text-white rounded-pill p-play px-2">
              PLAY VIDEO
            </p>
          )}
        </div>
      )}
    </>
  )
}

const StopBtn = ({ onClick }) => {
  return (
    <p onClick={onClick} className="pointer bg-primary text-white rounded-pill p-play px-2">
      STOP
    </p>
  )
}

export default PlayButtons
