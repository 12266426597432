/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 12-11-2020.
 */
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { userStore } from '../../store/User'
import { animated, useTransition } from 'react-spring'

import { LazyImage } from '../display/LazyImage'

let currentImage = ''
const PinAnimation = memo((props) => {
  const pinItem = userStore((state) => state.pinItem)

  const [show, setShow] = useState(false)
  const { projectName, imgPath } = pinItem

  useEffect(() => {
    if (currentImage !== imgPath) {
      console.log(' PinAnimation > RUN = ')
      setShow(true)
    }

    currentImage = imgPath
  }, [pinItem])

  const ypos = window.scrollY - 50
  const transition = useTransition(show, {
    from: { opacity: 0.5, transform: `translate3d(${window.innerWidth - 200}px,${ypos}px,0)` },
    enter: { opacity: 1, transform: `translate3d(${window.innerWidth - 230}px,${ypos}px,0)` },
    leave: { opacity: 0, transform: `translate3d(${window.innerWidth}px,${ypos}px,0)` },
    config: { friction: 30, tension: 700 },
    onRest: (what) => {
      if (what.finished && what.value.opacity !== 0) {
        console.log(' PinAnimation > what= ', what)
        setTimeout(() => {
          setShow(false)
        }, 1800)
      }
    },
  })

  return (
    <div className="position-fixed no-pointer-events vh-100 w-100 noselect " style={{ zIndex: 100 }}>
      {transition((style, item, t, i) => {
        return item ? (
          <animated.div
            className="position-fixed no-pointer-events"
            style={{
              ...style,
            }}
          >
            <div className="no-pointer-events overflow-hidden" style={{}}>
              <LazyImage
                className="d-flex rounded-circle justify-content-center position-relative overflow-hidden align-items-center"
                style={{
                  pointerEvents: 'none',
                  width: 200,
                  height: 200,
                  background: 'rgba(255,255,255,.8)',
                }}
                imgStyle={{ width: 132, height: 100, borderRadius: 10 }}
                imgUrl={imgPath + '&w=200'}
              ></LazyImage>
              {/*<img className="w-100 h-auto" src={pinItem.imgPath + '&w=200'} alt="" />*/}
              <div className="position-absolute w-100" style={{ top: 154 }}>
                <p className="p-play text-center text-muted position-relative">Added to</p>
                <p className="p-play text-center position-relative" style={{ color: 'black' }}>
                  {projectName}
                </p>
              </div>
            </div>
          </animated.div>
        ) : null
      })}
    </div>
  )
})

export default PinAnimation

/*
 <div className="position-fixed vh-100 w-100 noselect " style={{ pointerEvents: 'none', zIndex: 100 }}>
      <div
        className="position-absolute "
        style={{
          background: 'red',
          transform: `translate3d(${mouseEvent.clientX + 10}px, ${mouseEvent.clientY + 20}px, 0)`,
        }}
      >
        <div className="no-pointer-events overflow-hidden" style={{ zIndex: 1000, width: 198, height: 166 }}>
          <img className="w-100 h-auto" src={pinItem.imgPath + '&w=200'} alt="" />
        </div>
      </div>
    </div>
 */
