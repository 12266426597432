/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 22-09-2020.
 */
import React from 'react'
import { BOARD_PAGE, COLOR_PRIMARY } from '../../constants'
import { NavLink, useLocation } from 'react-router-dom'
import { userStore } from '../../store/User'
import { useAuthDispatch } from '../../hooks/AuthContext'

const Submenu = (props) => {
  const boards = userStore((state) => state.boards)

  const location = useLocation()

  return (
    <div className="pl-2">
      {boards.map((board, subIndex) => {
        const to = board.project_name.toLowerCase().split(' ').join('_').replace('/', '-')
        const current = location.pathname.split('/board/').join('')

        const isActive = board.project_id === current
        return (
          <NavLink
            key={subIndex}
            to={'/board/' + to}
            className="position-relative"
            activeStyle={{
              color: '#050505',
            }}
            style={{
              color: '#757575',
            }}
          >
            <p key={subIndex} className="mb-2 p-small text-truncate w-100 pr-3" style={{}}>
              {board.project_name}
            </p>
            {isActive && (
              <div
                className="position-absolute rounded-circle"
                style={{ top: 7, left: -14, background: COLOR_PRIMARY, width: 7, height: 7 }}
              ></div>
            )}
          </NavLink>
        )
      })}
    </div>
  )
}
export default Submenu
