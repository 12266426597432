/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 10-09-2020.
 */
import React, { useEffect, useState } from 'react'

const TextArea = ({ style, placeholder, inputChangeFunc, initialTxt }) => {
  const [txt, setTxt] = useState('')
  const handleChange = (e) => {
    setTxt(e.target.value)
    if (inputChangeFunc) {
      inputChangeFunc(e.target.value)
    }
  }

  useEffect(() => {
    setTxt(initialTxt)
  }, [initialTxt])

  return (
    <textarea
      value={txt}
      onChange={handleChange}
      className="w-100 px-3"
      placeholder={placeholder}
      rows={6}
      cols={5}
      style={{
        fontStyle: 'italic',
        color: '#4e4e4e',
        border: 'none',
        outline: 'none',
        background: 'transparent',
        ...style,
      }}
    />
  )
}
export default TextArea
