/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 11-09-2020.
 */
import React, { useEffect, useRef, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { userStore } from '../../store/User'
import { useHover } from 'react-use-gesture'
import { a, useSpring, useTransition } from 'react-spring'

const BoardList = ({ show = true, showMenuClick, frame, top = 30 }) => {
  //console.log(' BoardList > frame = ', frame)
  // save a cut to a board by project_id
  const addCutToBoard = userStore((state) => state.addCutToBoard)
  const containerRef = useRef()
  const overlayOpacity = useSpring({
    opacity: 0.5,
    config: { friction: 25, tension: 400 },
  })

  const boards = userStore((state) => state.boards)

  const transition = useTransition(show, {
    from: { opacity: 1, transform: 'scale(0.5,0.5)' },
    enter: {
      opacity: 1,
      transform: 'scale(1,1)',
    },
    leave: {
      opacity: 0,
      transform: 'scale(0.5,0.5)',
    },
    config: { friction: 30, tension: 700 },
  })

  const saveClick = (projectId, projectName) => {
    //saveCut(projectId)
    const cutId = frame.cutName
    //console.log(' BoardList > frame = ', frame)

    const obj = {
      project_id: projectId,
      config: {
        cut_id: cutId,
        comment: '',
        placement: '',
      },
    }
    showMenuClick(false)
    //const rect = containerRef.current.getBoundingClientRect()
    //const event = { clientX: rect.x, clientY: rect.y - rect.height }
    addCutToBoard(obj, frame.cutUrl, projectName).then((res) => {
      console.log(' BoardList > res = ', res)
    })
  }

  const menuHeight = 210
  return (
    <>
      {transition((style, item, t, i) => {
        return item ? (
          <a.div
            ref={containerRef}
            className="position-absolute w-100 "
            style={{
              top: top,
              right: 10,
              background: 'white',
              borderRadius: 6,
              zIndex: 600,
              maxHeight: menuHeight,
              boxShadow: '0px 12px 25px -5px rgba(0, 0, 0, 0.25)',
              transformOrigin: 'top right',
              ...style,
            }}
          >
            <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={menuHeight - 35}>
              <div className="h-100 mb-3">
                <p className="p-play mb-1 pl-3 pt-3 text-black-50">Your Boards</p>
                {boards.map((item, index) => {
                  return <MenuItem key={index} data={item} saveClick={saveClick}></MenuItem>
                })}
              </div>
            </Scrollbars>
            {/*<div className="pointer mb-1 mt-1 pl-2 w-100 d-flex justify-content-between">
              <PlusIcon
                className="bg-primary mt-1 rounded-circle"
                style={{ height: '1.1rem', width: '1.3rem' }}
              ></PlusIcon>

              <p className="mb-0 mt-1 px-2 p-small text-truncate w-100">Create board</p>
            </div>*/}
          </a.div>
        ) : null
      })}
    </>
  )
}

export default BoardList

const MenuItem = ({ data, saveClick }) => {
  const [{ opacity, background }, set] = useSpring(() => ({
    opacity: 0,
    background: '#FFFFFF',
  }))

  const bind = useHover(({ event, last, active }) => {
    set({
      opacity: active ? 1 : 0,
      background: active ? '#e5e5e5' : '#FFFFFF',
    })
  })

  const click = (e) => {
    const projectId = data.project_id
    saveClick(projectId, data.project_name)
  }

  return (
    <a.div
      {...bind()}
      onClick={click}
      className="pointer pl-3 pr-2 w-100 d-flex py-1 justify-content-between"
      style={{ background: background }}
    >
      <p className="mb-0 p-small text-truncate w-100">{data.project_name}</p>
      <a.div style={{ marginTop: 3, opacity: opacity, willChange: 'opacity' }}>
        <p className="mb-0 bg-primary rounded-pill px-1 p-play text-white">save</p>
      </a.div>
    </a.div>
  )
}
