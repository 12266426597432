/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 17-09-2020.
 */
import React from 'react'
import { a, useSpring } from 'react-spring'
import { useHover } from 'react-use-gesture'

import VideoPlayer from '../../components/videoplayer/VideoPlayer'
import { userStore } from '../../store/User'
import Comment from './Comment'

const BoardCut = ({ boardId, item, deleteCut }) => {
  const { cut } = item
  const fullVideoUrl = cut.parentVideo ? cut.parentVideo.videoUrl : ''

  const validateGlobalPlaying = userStore((state) => state.validateGlobalPlaying)

  const [aniProps, setOp] = useSpring(() => ({
    opacity: 0,
  }))

  const bind = useHover(({ event, last, active }) => {
    setOp({
      opacity: active ? 1 : 0,
    })
  })

  const deleteClick = () => {
    deleteCut(item)
  }

  const globalVideo = userStore((state) => state.globalVideo)

  // VIDEO CONTROLS
  //---------------------------------------------------------------------------------------

  //
  // in order to control how many videos are playing at once, the current video path is placed in global state.
  // this causes a problem when the same cut can be played from multiple places.
  //

  console.log(' BoardCut > cut.cutName = ', cut)
  const videoIdAppend = ':' + cut.cutName // using the cutName since the cut should only be on the board one time

  const videoToPlay = fullVideoUrl + videoIdAppend

  const togglePlaying = globalVideo === videoToPlay || globalVideo === cut.cutVideo
  const cleanGlobalVideo = togglePlaying ? globalVideo.split(videoIdAppend)[0] : globalVideo
  //const togglePlaying = globalVideo === cut.cutVideo || globalVideo === cut.parentVideo.videoUrl

  console.log(' BoardCut > globalVideo = ', globalVideo, cleanGlobalVideo, togglePlaying)

  const playCut = () => {
    validateGlobalPlaying(cut.cutVideo)
  }
  const playFull = () => {
    // appendToPath={videoIdAppen}
    //validateGlobalPlaying(cut.parentVideo.videoUrl)
    validateGlobalPlaying(videoToPlay)
  }

  const stopVideo = () => {
    validateGlobalPlaying('')
  }

  const imgUrl = cut.cutImage || ''

  return (
    <div
      {...bind()}
      style={{
        background: '#dfdfdf',
      }}
      className="w-100 h-100 "
    >
      <div
        className="w-100  h-100 position-relative"
        style={{
          cursor: 'move',
          background: '#dfdfdf',
          backgroundImage: 'url(' + imgUrl + ')',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        }}
      >
        {/* {cut.ID}*/}
        <a.div
          className="w-100 h-100 position-absolute"
          style={{ top: 0, left: 0, ...aniProps, background: 'rgba(0,0,0,.8)' }}
        >
          <div className="d-flex p-2 flex-column w-100 h-100 justify-content-between ">
            <div className="w-100 d-flex justify-content-between">
              {/*COMMENTS*/}

              <Comment boardId={boardId} cut={cut}></Comment>

              {/*DELETE CUT*/}
              <p onClick={deleteClick} className="text-white p-play pointer" style={{ opacity: 0.8 }}>
                DELETE
              </p>
            </div>

            <div className="w-100 d-flex justify-content-between">
              <div className="align-items-end d-flex text-white">
                <div onClick={playCut} className="mr-1 ">
                  <p className="pointer bg-primary text-white rounded-pill p-play px-2">PLAY CUT</p>
                </div>

                <div className="" onClick={playFull}>
                  <p className="pointer bg-primary text-white rounded-pill p-play px-2">PLAY VIDEO</p>
                </div>
              </div>
            </div>
          </div>
        </a.div>

        <a.div
          className="position-absolute draggableHandle "
          style={{
            bottom: 0,
            right: 0,
            width: 10,
            height: 10,
            borderStyle: 'solid',
            borderWidth: '0 0 20px 20px',
            borderColor: 'transparent transparent #007bff transparent',
            ...aniProps,
          }}
        ></a.div>

        <Video
          onClick={stopVideo}
          globalVideo={cleanGlobalVideo}
          togglePlaying={togglePlaying}
          cutVideo={cut.cutVideo}
          fullVideo={cleanGlobalVideo}
        ></Video>
      </div>
    </div>
  )
}
export default BoardCut

const Video = ({ cutVideo, onClick, fullVideo, globalVideo, togglePlaying }) => {
  //const globalVideo = userStore((state) => state.globalVideo)

  const show = togglePlaying //globalVideo === cutVideo || globalVideo === fullVideo

  return show ? (
    <div onClick={onClick} className="w-100 h-100 pointer position-absolute" style={{ zIndex: 0, background: 'black' }}>
      <p
        className="position-absolute pointer bg-primary text-white rounded-pill p-play px-2"
        style={{ bottom: 8, left: 8 }}
      >
        STOP
      </p>
      <VideoPlayer url={globalVideo} togglePlaying={togglePlaying}></VideoPlayer>
    </div>
  ) : null
}
