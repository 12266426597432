/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 09-09-2020.
 */
import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import { COLOR_PLACEHOLDER } from '../../constants'
import { ReactComponent as EmptyIcon } from '../../assets/svg/box.svg'
import Popup from 'reactjs-popup'
import Button from '../../components/display/Button'
import './modal.scss'

import hrt from 'human-readable-time'

import EditBoardModal from '../board/EditBoardModal'

const Project = ({ to, item, deleteClick, index, itemHeight = 120 }) => {
  const popupRef = useRef()
  const hasImage = item.image ? true : false

  const deleteme = () => {
    deleteClick(index)
    cancel()
  }

  const cancel = () => {
    popupRef.current.close()
  }

  const date = hrt(new Date(Number(item.create_time)), '%relative% ago')

  return (
    <div className="p-2 position-relative w-100">
      {deleteClick && (
        <Popup
          className="board-popup"
          ref={popupRef}
          trigger={
            <div className="position-absolute pl-2 pr-3  pt-2 pb-1" style={{ zIndex: 200, top: 4, left: 8 }}>
              <p
                className="mb-0 pointer rounded-pill text-white "
                style={{ padding: '0px 6px 0px 6px', fontSize: '.8rem', background: '#991e1e' }}
              >
                <small>DELETE</small>
              </p>
            </div>
          }
          position="top center"
          closeOnDocumentClick
        >
          <div className="p-2">
            <p className="p-small">
              <b>Peggy wants to be sure:</b>
            </p>
            <p className="p-small text-muted">
              Should <b>{item.project_name}</b> be forever deleted
            </p>
            <div className="d-flex justify-content-end mt-3">
              <Button filled onClick={cancel} style={{ marginRight: 10 }}>
                No
              </Button>
              <Button onClick={deleteme}>
                <p className="p-small mb-0">Yes</p>
              </Button>
            </div>
          </div>
        </Popup>
      )}

      {deleteClick && <EditBoardModal selectedBoard={item}></EditBoardModal>}

      <Link to={to} className="text-decoration-none">
        {hasImage ? (
          <div
            className="back-image w-100 overflow-hidden"
            style={{ height: itemHeight, backgroundImage: 'url(' + item.image + '&w=200' + ')', borderRadius: 10 }}
          ></div>
        ) : (
          <div
            className="overflow-hidden "
            style={{ height: itemHeight, background: COLOR_PLACEHOLDER, borderRadius: 10 }}
          >
            <EmptyIcon className="p-5 d-flex mx-auto h-100"></EmptyIcon>
          </div>
        )}

        <p className="p-play mt-2 mb-0 " style={{ color: 'black' }}>
          {date}
        </p>

        <p className="mb-0 text-black p-small text-truncate" style={{ maxWidth: '85%' }}>
          {item.project_name}
        </p>
      </Link>
    </div>
  )
}

export default Project
